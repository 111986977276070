import { INGXLoggerMetadata } from "ngx-logger";
import { ISettings } from "../../interfaces/settings.interface";
import * as SettingsActions from "./settings.action";

let initialSettings: ISettings = {
  language: "de",
  collapseTeamCalendar: true
};

export interface SettingsState {
  settings: ISettings;
  logs: INGXLoggerMetadata[];
}

const initialState: SettingsState = {
  settings: initialSettings,
  logs: [],
};

export function settingsReducer(
  state: SettingsState = initialState,
  action: SettingsActions.settingsActions
): SettingsState {
  switch (action.type) {
    case SettingsActions.SET:
      return {
        ...state,
        settings: action.payload,
      };
    case SettingsActions.SET_LANGUAGE:

      return {
        ...state,
        settings: { ...state.settings, language: action.payload },
      };
    case SettingsActions.TOGGLE_TEAM_CALENDAR:
      return {
        ...state,
        settings: { ...state.settings, collapseTeamCalendar: action.payload },
      };
    case SettingsActions.SET_LOGS:
      let loadedLogs = [...action.payload, ...state.logs]
      return {
        ...state,
        logs: loadedLogs,
      };
    case SettingsActions.ADD_LOG:
      let updatedLogs = [...state.logs, action.payload];
      if (updatedLogs.length > 1000) {
        updatedLogs.shift();
      }
      return {
        ...state,
        logs: updatedLogs,
      };
    case SettingsActions.RESET_LOGS:
      return {
        ...state,
        logs: [],
      };


    default:
      return state;
  }
}

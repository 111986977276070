import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-hours-progress',
  templateUrl: './hours-progress.component.html',
  styleUrls: ['./hours-progress.component.scss'],
})
export class HoursProgressComponent implements  OnChanges {
  isHoursDisplay: string = "00:00";
  shouldHoursDisplay: string = "00:00";
  colorShouldHours = "medium";
  @Input() title: string = '';
  @Input() isHours: string = "0";
  @Input() shouldHours: string = "";
  @ViewChild('progressBar', { static: true}) progressBar!: ElementRef;
  @ViewChild('workHours', { static: true }) workHours!: ElementRef;

  constructor(
    private sharedService: SharedService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.getPercentageBetweenTimes(+this.isHours, +this.shouldHours);

    let tmp = +this.isHours - +this.shouldHours;

    this.colorShouldHours = "medium";

    if (tmp < 0 ) {
      this.colorShouldHours = "app-danger"
    }

    if (tmp > 0) {
      this.colorShouldHours = "app-success"
    }

    this.isHoursDisplay = this.sharedService.convertMinutesToHHMM(+this.isHours)
    this.shouldHoursDisplay = this.sharedService.convertMinutesToHHMM(+tmp)
  }

  /**
  * getTimeInMilliseconds - converts a time string in format "HH:MM" to milliseconds
  * @param {string} time - time in string format "HH:MM"
  * @returns {number} - time in milliseconds
  */
  getTimeInMilliseconds(time: string): number {
    const [hours, minutes] = time.split(":").map(n => parseInt(n));
    return (hours * 3600 + minutes * 60) * 1000;
  }

  /**
  * getPercentageBetweenTimes - calculates the percentage between the start and end times
  * @param {string} startTime - start time in string format
  * @param {string} endTime - end time in string format
  */
  getPercentageBetweenTimes(startTime: number, endTime: number) {

    const startTimeInMilliseconds = startTime * 60 * 1000;
    const endTimeInMilliseconds = endTime * 60 * 1000;

    let percentage = startTimeInMilliseconds / endTimeInMilliseconds;
    // Set overtime
    if ( percentage > 1) {
      percentage = 1 / percentage;
      this.progressBar.nativeElement.style.backgroundColor = 'var(--ion-color-app-success)';
    } else {
      this.progressBar.nativeElement.style.backgroundColor = 'var(--ion-color-medium)';
    }
    this.workHours.nativeElement.style.width = `${percentage * 100}%`;
  }

}

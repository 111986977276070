import { Action } from "@ngrx/store";

export const FETCH = '[TIME] Fetch';
export const UPDATE = '[TIME] Update';
export const ASSIGN = '[TIME] Assign';

export class Fetch implements Action {
    readonly type = FETCH;
}
export class Update implements Action {
    readonly type = UPDATE;
}
export class Assign implements Action {
    readonly type = ASSIGN;
    constructor(public payload: Date) { };
}

export type TimeActions = Fetch | Update | Assign
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { Store } from '@ngrx/store';
import { IAppState } from '../app/app.reducer';
import { debounceTime, map, of, switchMap, withLatestFrom } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { EMessageType } from '../../enums/message-type.enum';
import { AuthState } from '../auth/auth.reducer';
import * as UIActions from "src/app/core/store/ui/ui.actions";
import * as AuthActions from "src/app/core/store/auth/auth.actions";
import * as SettingsActions from "src/app/core/store/settings/settings.action";
import * as AbsenceActions from "src/app/core/store/absences/absences.actions";
import { Employee } from '../../models/employee.model';
import { Admin } from '../../models/admin.model';
import { Board } from '../../models/board.model';

@Injectable()

export class UIEffects {
    error$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UIActions.ERROR),
            withLatestFrom(
                this.store.select('account')
            ),
            switchMap(([uiState, accountState]: [UIActions.Error, AuthState]) => {

                let message = this.translateService.instant("CORE.MESSAGES.ERRORS.UNKNOWN");
                let supportFile = true;
                let type = EMessageType.ERROR;
                let internal = uiState.payload.internal;

                if (typeof uiState.payload.error === "string") {
                    message = uiState.payload.error;
                }

                if (uiState.payload.error instanceof HttpErrorResponse) {

                    if (uiState.payload.error.error.error && uiState.payload.error.error.error == "Does not exists any absence with the specified id") {
                        type = EMessageType.WARNING;
                        message = this.translateService.instant("CORE.MESSAGES.WARNINGS.ABSENCE_DELETED");
                        if (accountState.account instanceof Employee) {
                            this.store.dispatch(new AbsenceActions.Fetch({ id: accountState.account.userId, navigate: false }))
                        } else if ((accountState instanceof Admin || Board) && accountState.alias) {
                            this.store.dispatch(new AbsenceActions.Fetch({ id: accountState.alias?.userId, navigate: false }))
                        } else {
                            this.store.dispatch(new AbsenceActions.FetchOpen(false))
                        }
                    } else if (uiState.payload.error.url?.includes("/logs")) {
                        this.store.dispatch(new SettingsActions.ResetLogs());
                        message = this.translateService.instant("CORE.MESSAGES.ERRORS.SUPPORT_FILE");
                        supportFile = false;
                    } else {
                        message = uiState.payload.error.message;
                    }
                }

                if (uiState.payload.error instanceof Error ||
                    uiState.payload.error instanceof SyntaxError ||
                    uiState.payload.error instanceof TypeError ||
                    uiState.payload.error instanceof ReferenceError ||
                    uiState.payload.error instanceof RangeError ||
                    uiState.payload.error instanceof URIError ||
                    uiState.payload.error instanceof EvalError
                ) {
                    message = uiState.payload.error.message;
                }

                if (uiState.payload.error && uiState.payload.error.error) {
                    switch (uiState.payload.error.error) {
                        case 'invalid_grant':
                            this.store.dispatch(new AuthActions.SessionLogout());
                            message = this.translateService.instant("CORE.MESSAGES.ERRORS.INCORRECT_CREDENTIALS");
                            supportFile = false;
                            internal = false;
                            type = EMessageType.INFORMATION;
                            break;
                        case 'invalid_request':
                            this.store.dispatch(new AuthActions.SessionLogout());
                            message = this.translateService.instant("CORE.MESSAGES.ERRORS.SESSION_EXPIRED");
                            supportFile = false;
                            internal = false;
                            type = EMessageType.INFORMATION;
                            break;
                        case 'Unauthenticated.':
                            this.store.dispatch(new AuthActions.SessionLogout());
                            message = this.translateService.instant("CORE.MESSAGES.ERRORS.SESSION_EXPIRED");
                            supportFile = false;
                            internal = false;
                            type = EMessageType.INFORMATION;
                            break;
                    }
                }

                if (uiState.payload.error && uiState.payload.error.error && uiState.payload.error.error.error) {
                    switch (uiState.payload.error.error.error) {
                        case 'invalid_grant':
                            this.store.dispatch(new AuthActions.SessionLogout());
                            message = this.translateService.instant("CORE.MESSAGES.ERRORS.INCORRECT_CREDENTIALS");
                            supportFile = false;
                            internal = false;
                            type = EMessageType.INFORMATION;
                            break;
                        case 'invalid_request':
                            this.store.dispatch(new AuthActions.SessionLogout());
                            message = this.translateService.instant("CORE.MESSAGES.ERRORS.SESSION_EXPIRED");
                            supportFile = false;
                            internal = false;
                            type = EMessageType.INFORMATION;
                            break;
                        case 'Unauthenticated.':
                            this.store.dispatch(new AuthActions.SessionLogout());
                            message = this.translateService.instant("CORE.MESSAGES.ERRORS.SESSION_EXPIRED");
                            supportFile = false;
                            internal = false;
                            type = EMessageType.INFORMATION;
                            break;
                    }
                }

                if (uiState.payload.error.error?.error?.email && uiState.payload.error.error?.error?.email[0]) {

                    switch (uiState.payload.error.error.error.email[0]) {
                        case 'The email has already been taken.':
                            message = this.translateService.instant("CORE.MESSAGES.ERRORS.EMAIL_ADDRESS_TAKEN");
                            supportFile = false;
                            break;
                        case 'The selected email is invalid.':
                            message = this.translateService.instant("CORE.MESSAGES.ERRORS.EMAIL_ADDRESS_UNKNOWN");
                            supportFile = false;
                            break;
                    }
                }

                if (message instanceof String && message.length > 250) {
                    message = message.slice(0, 250);
                }

                switch (type) {
                    case EMessageType.INFORMATION:
                        this.logger.info(message);
                        return of(new UIActions.Message({ message: message, type: type }));
                        break;
                    case EMessageType.WARNING:
                        this.logger.warn(message);
                        return of(new UIActions.Message({ message: message, type: type }));
                    default:
                        this.logger.error(message);
                        return of(new UIActions.ErrorMessage({ message: message, supportFile: supportFile, internal: internal }))

                }
            })
        )
    )

    stopSplash$ = createEffect(() =>
            this.actions$.pipe(
                ofType(UIActions.STOP_SPLASH),
                debounceTime(1000),
                map(() => {
                    return new UIActions.HideSplash();
                })
            )
    )

    constructor(
        private actions$: Actions,
        private store: Store<IAppState>,
        private translateService: TranslateService,
        private logger: NGXLogger
    ) { }
}
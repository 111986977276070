import { Action } from "@ngrx/store";
import { Accounts } from "../../interfaces/account.types";
import { AuthData } from "../../models/auth-data.model";
import { Employee } from "../../models/employee.model";

export const START_LOGIN = '[AUTH] Start Login';
export const REFRESH_AUTHDATA = '[AUTH] Refresh AuthData';
export const FETCH_ACCOUNT = '[AUTH] Fetch Account';
export const LOGIN = '[AUTH] Login';
export const UPDATE = '[AUTH] Update';
export const PASSWORD_UPDATE = '[AUTH] Password Update';
export const PASSWORD_RESET = '[AUTH] Password Reset';
export const LOGIN_FAILED = '[AUTH] Login failed';
export const AUTO_LOGIN = '[AUTH] Auto Login';
export const LOGOUT = '[AUTH] Logout';
export const SESSION_LOGOUT = '[AUTH] Session Logout';
export const FETCH_ALIAS = '[AUTH] Fetch Alias';
export const SET_ALIAS = '[AUTH] Set Alias';
export const DELETE_ALIAS = '[AUTH] Delete Alias';

export class StartLogin implements Action {
    readonly type = START_LOGIN;
    constructor(public payload: { email: string, password: string }) { };
}

export class RefreshAuthData implements Action {
    readonly type = REFRESH_AUTHDATA;
    constructor(public payload: AuthData) { };
}

export class FetchAccount implements Action {
    readonly type = FETCH_ACCOUNT;
    constructor(public payload: AuthData) { };
}

export class Login implements Action {
    readonly type = LOGIN;
    constructor(public payload: Accounts) { };
}

export class Update implements Action {
    readonly type = UPDATE;
    constructor(public payload: Accounts) { };
}

export class PasswordUpdate implements Action {
    readonly type = PASSWORD_UPDATE;
    constructor(public payload: { email: string, password: string, password_confirmation: string }) { };
}

export class LoginFailed implements Action {
    readonly type = LOGIN_FAILED;
    constructor(public payload: any) { };
}

export class AutoLogin implements Action {
    readonly type = AUTO_LOGIN;
}
export class Logout implements Action {
    readonly type = LOGOUT;
}

export class SessionLogout implements Action {
    readonly type = SESSION_LOGOUT;
}

export class PasswordReset implements Action {
    readonly type = PASSWORD_RESET;
    constructor(public payload: string) { };
}

export class FetchAlias implements Action {
    readonly type = FETCH_ALIAS;
    constructor(public payload: string) { };
}

export class SetAlias implements Action {
    readonly type = SET_ALIAS;
    constructor(public payload: Accounts ) { };
}

export class DeleteAlias implements Action {
    readonly type = DELETE_ALIAS;
    constructor(public navigate: boolean = true) { };
}

export type AuthActions = StartLogin | RefreshAuthData | FetchAccount | Login | Update | LoginFailed | AutoLogin | Logout | SessionLogout | PasswordReset | FetchAlias | SetAlias | DeleteAlias
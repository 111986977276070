<app-splash-screen *ngIf="showSplash"></app-splash-screen>
<ion-app>
  <ion-router-outlet id="main-content"></ion-router-outlet>
  <div *ngIf="isLoading" class="loading-container">
    <ion-backdrop [visible]="true"></ion-backdrop>
    <div class="loading-wrapper">
      <ion-spinner name="bubbles" color="medium"></ion-spinner>
      <ion-text color="medium">{{'CORE.LOADING' | translate}}...</ion-text>
    </div>
  </div>
</ion-app>
import { Component, OnInit, ViewChild, ElementRef, Input, OnChanges, SimpleChanges } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { EAbsenceStatus, EAbsencesType } from "src/app/core/enums/absences.enum";
import { Accounts } from "src/app/core/interfaces/account.types";
import { Absence } from "src/app/core/models/absence.model";
import { Employee } from "src/app/core/models/employee.model";
import { SharedService } from "src/app/shared/services/shared.service";

@Component({
  selector: "app-vacation-overview",
  templateUrl: "./vacation-overview.component.html",
  styleUrls: ["./vacation-overview.component.scss"],
})
export class VacationOverviewComponent implements OnInit, OnChanges {
  @Input() account!: Accounts | undefined;
  @Input() alias!: Accounts | undefined;
  @Input() absences: Absence[] = [];
  public totalVacationDays: number = 0;
  public openVacationDays: number = 0;
  public approvedVacationDays: number = 0;
  public carryOverVacationDays: number = 0;
  @ViewChild("approvedCount", { static: true }) approvedCount!: ElementRef;
  @ViewChild("openCount", { static: true }) openCount!: ElementRef;
  @ViewChild("progressApproved", { static: true }) progressApproved!: ElementRef;
  @ViewChild("progressOpen", { static: true }) progressOpen!: ElementRef;
  constructor(private logger: NGXLogger, private sharedService: SharedService) { }

  ngOnInit() {
    this.logger.info("OnInit: Initialize vacation overview component");
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.getVacationCount();
      this.getOpenVacationDays();
      this.getApprovedVacationDays();
    }
  }

  getVacationCount() {
    if (this.alias instanceof Employee) {
      this.totalVacationDays = this.sharedService.currentWorkTimeScheme(this.alias.workTimeSchemes).vacationDays!;
      this.carryOverVacationDays = this.sharedService.getCarryOverVacationDays(this.alias.workTimeSchemes);
    }
    if (this.account instanceof Employee) {
      this.totalVacationDays = this.sharedService.currentWorkTimeScheme(this.account.workTimeSchemes).vacationDays!;
      this.carryOverVacationDays = this.sharedService.getCarryOverVacationDays(this.account.workTimeSchemes);
    }
  }

  getApprovedVacationDays() {
    this.approvedVacationDays = 0;

    for (const absence of this.absences) {
      if (absence.type == EAbsencesType.VACATION && absence.status == EAbsenceStatus.APPROVED) {
        this.approvedVacationDays =
          this.approvedVacationDays + this.sharedService.getAbsenceDurationCount(absence.startDate, absence.endDate);
      }
    }

    const WIDTH = (this.approvedVacationDays / (this.totalVacationDays + this.carryOverVacationDays)) * 100;

    this.progressApproved.nativeElement.style.width = `${WIDTH}%`;

    if (WIDTH < 4) {
      this.approvedCount.nativeElement.style.paddingLeft = `2px`;
    }

    if (this.approvedVacationDays == 0 && this.openVacationDays != 0) {
      this.approvedCount.nativeElement.style.display = "none";
    } else {
      this.approvedCount.nativeElement.style.display = "block";
    }
  }

  getOpenVacationDays() {
    this.logger.info("Getting open vacation days");
    this.openVacationDays = 0;

    for (const absence of this.absences) {
      if (absence.type == EAbsencesType.VACATION && absence.status == EAbsenceStatus.OPEN) {
        this.openVacationDays = this.openVacationDays + this.sharedService.getAbsenceDurationCount(absence.startDate, absence.endDate);
      }
    }

    const WIDTH = (this.openVacationDays / (this.totalVacationDays + this.carryOverVacationDays)) * 100;

    this.progressOpen.nativeElement.style.width = `${WIDTH}%`;

    if (WIDTH < 5) {
      this.openCount.nativeElement.style.paddingLeft = `3px`;
    }

    if (WIDTH < 4) {
      this.openCount.nativeElement.style.paddingLeft = `1px`;
    }

    if (WIDTH < 3) {
      this.openCount.nativeElement.style.paddingLeft = `0px`;
    }

    if (this.openVacationDays == 0) {
      this.openCount.nativeElement.style.display = "none";
    } else {
      this.openCount.nativeElement.style.display = "block";
    }
  }
}

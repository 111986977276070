import { isAfter } from "date-fns";
import { IAuthData } from "../interfaces/auth-response.interface";

export class AuthData implements IAuthData {

    private _tokenType: string;
    private _expiresIn: number;
    private _accessToken: string;
    private _refreshToken: string;
    private _tokenExpirationDate: Date;

    constructor(authData: IAuthData) {
        this._tokenType = authData.tokenType;
        this._expiresIn = authData.expiresIn;
        this._accessToken = authData.accessToken;
        this._refreshToken = authData.refreshToken;
        this._tokenExpirationDate = authData.tokenExpirationDate;
    }

    get tokenType(): string {
        return this._tokenType;
    }

    get expiresIn(): number {
        return this._expiresIn;
    }

    get accessToken(): string {
        return this._accessToken;
    }

    get refreshToken(): string {
        return this._refreshToken;
    }

    isTokenValid(date: Date): boolean {
        return !isAfter(date, this._tokenExpirationDate);
    }

    get tokenExpirationDate(): Date {
        return this._tokenExpirationDate;
    }
}
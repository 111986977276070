export enum EAbsencesType {
    VACATION,
    OVERTIME,
    TRAINING,
    SICK_LEAVE,
    HIDDEN
}

export enum EAbsenceNotified {
    NOT_NOTIFIED,
    NOTIFIED
}

export enum EAbsenceStatus {
    OPEN,
    APPROVED,
    REJECTED
}
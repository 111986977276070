import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, from, map, of, switchMap, tap, withLatestFrom } from "rxjs";
import { Store } from "@ngrx/store";
import { StorageService } from "src/app/shared/services/storage.service";
import { EStorageKey } from "../../enums/storage-key.enum";
import { TranslateService } from '@ngx-translate/core';
import { IAppState } from "../app/app.reducer";
import { SettingsState } from "./settings.reducer";
import { INGXLoggerMetadata, NGXLogger } from "ngx-logger";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { AuthState } from "../auth/auth.reducer";
import { SharedService } from "src/app/shared/services/shared.service";
import { format } from "date-fns-tz";
import { EMessageType } from "../../enums/message-type.enum";
import * as SettingsAction from "./settings.action";
import * as UIActions from '../ui/ui.actions';

@Injectable()
export class SettingsEffects {

  set$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsAction.SET),
        tap((action: SettingsAction.Set) => {
          this.logger.info("Set settings to store");
          this.translateService.use(action.payload.language)
          this.storage.set(EStorageKey.SETTINGS, action.payload);
        }), catchError((error) => {
          return of(new UIActions.Error({ error: error, internal: false }));
        })
      ),
    { dispatch: false }
  );

  setLanguage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsAction.SET_LANGUAGE),
        withLatestFrom(
          this.store.select('settings')
        ),
        switchMap(([action, settingsState]: [SettingsAction.SetLanguage, SettingsState]) => {
          this.logger.info(`Changed app language to ${action.payload}`);
          return of(new SettingsAction.Set(settingsState.settings));
        }), catchError((error) => {
          return of(new UIActions.Error({ error: error, internal: false }));
        })
      ),
  );

  toggleTeamCalendar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsAction.TOGGLE_TEAM_CALENDAR),
        withLatestFrom(
          this.store.select('settings')
        ),
        switchMap(([action, settingsState]: [SettingsAction.SetLanguage, SettingsState]) => {
          this.logger.info(`Team calendar avatar section toggled to: ${action.payload}`);
          return of(new SettingsAction.Set(settingsState.settings));
        }), catchError((error) => {
          return of(new UIActions.Error({ error: error, internal: false }));
        })
      ),
  );

  fetch$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsAction.FETCH),
        switchMap(() => {
          this.logger.info("Fetching Settings from locale store");
          return from(
            this.storage.get(EStorageKey.SETTINGS).then((value) => {
              return value;
            })
          );
        }),
        tap((val) => {
          if (val) {
            this.store.dispatch(new SettingsAction.Set(val));
          }
        }), catchError((error) => {
          return of(new UIActions.Error({ error: error, internal: false }));
        })
      ),
    { dispatch: false }
  );

  saveLogs$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsAction.SAVE_LOGS),
        withLatestFrom(this.store.select('settings')),
        tap(([action, state]: [SettingsAction.SaveLogs, SettingsState]) => {
          this.storage.set(EStorageKey.LOGS, state.logs);
        }), catchError((error) => {
          return of(new UIActions.Error({ error: error, internal: false }));
        })
      ),
    { dispatch: false }
  );

  setLogs$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsAction.SET_LOGS),
        tap((action: SettingsAction.SetLogs) => {
        }), catchError((error) => {
          return of(new UIActions.Error({ error: error, internal: false }));
        })
      ),
    { dispatch: false }
  );

  fetchLogs$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsAction.FETCH_LOGS),
        switchMap(() => {
          return from(
            this.storage.get(EStorageKey.LOGS).then((value) => {
              return value;
            })
          );
        }),
        tap((val: INGXLoggerMetadata[]) => {
          if (val) {
            this.store.dispatch(new SettingsAction.SetLogs(val));
          }
        }), catchError((error) => {
          return of(new UIActions.Error({ error: error, internal: false }));
        })
      ),
    { dispatch: false }
  );

  addLog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsAction.ADD_LOG),
        map((action: SettingsAction.AddLog) => {
          return new SettingsAction.SaveLogs();
        }), catchError((error) => {
          return of(new UIActions.Error({ error: error, internal: false }));
        })
      )
  );

  sendLogs$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsAction.SEND_LOGS),
        tap((action: SettingsAction.SendLogs) => {
          this.store.dispatch(new UIActions.StartLoading());
          this.logger.info("Sending logs to server");
        }),
        withLatestFrom(
          this.store.select('settings'),
          this.store.select('account')
        ),
        switchMap(([action, state, account]: [SettingsAction.SendLogs, SettingsState, AuthState]) => {

          const body = {
            system: {
              user: account.account ? account.account.fullName : 'undefined',
              appVersion: environment.version,
              appDate: format(this.sharedService.serverTime, 'yyyy-MM-dd HH:mm:ssXXX', { timeZone: 'Europe/Berlin' }),
              id: this.sharedService.deviceID?.uuid || 'undefined',
              name: this.sharedService.deviceInfo?.name || 'undefined',
              isVirtual: this.sharedService.deviceInfo?.isVirtual?.toString() || 'undefined',
              manufacturer: this.sharedService.deviceInfo?.manufacturer || 'undefined',
              model: this.sharedService.deviceInfo?.model || 'undefined',
              operatingSystem: this.sharedService.deviceInfo?.operatingSystem || 'undefined',
              osVersion: this.sharedService.deviceInfo?.osVersion || 'undefined',
              platform: this.sharedService.deviceInfo?.platform || 'undefined',
              memUsed: this.sharedService.deviceInfo?.memUsed?.toString() || 'undefined',
              webViewVersion: this.sharedService.deviceInfo?.webViewVersion || 'undefined',
              realDiskFree: this.sharedService.deviceInfo?.realDiskFree?.toString() || 'undefined',
              realDiskTotal: this.sharedService.deviceInfo?.realDiskTotal?.toString() || 'undefined',
              batteryLevel: this.sharedService.deviceInfo?.batteryLevel?.toString() || 'undefined',
              isCharging: this.sharedService.deviceBatteryInfo?.isCharging?.toString() || 'undefined',
              systemLanguageCode: this.sharedService.deviceLanguageCode?.value || 'undefined',
              appLanguageCode: state.settings.language,
              comment: action.payload || 'undefined'
            },
            logs: state.logs
          }

          return this.http.post<any>(environment.apiUrl + 'logs', JSON.stringify(body), { headers: { 'Content-Type': 'application/json' }, observe: 'response' }).pipe(
            map(() => {
              return new UIActions.Message({
                message: this.translateService.instant("CORE.MESSAGES.SUPPORT_FILE_SEND"),
                type: EMessageType.INFORMATION
              });
            }),
            catchError((error) => {
              this.logger.error(error.message);
              return of(new UIActions.Error({ error: error, internal: false }));
            })
          )
        })
      )
  );

  resetLog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsAction.RESET_LOGS),
        switchMap(() => {
          return from(
            this.storage.remove(EStorageKey.LOGS)
          );
        }), catchError((error) => {
          return of(new UIActions.Error({ error: error, internal: false }));
        })
      ), { dispatch: false }
  );

  constructor(
    private translateService: TranslateService,
    private actions$: Actions,
    private storage: StorageService,
    private store: Store<IAppState>,
    private logger: NGXLogger,
    private http: HttpClient,
    private sharedService: SharedService
  ) { }
}

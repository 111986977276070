import { Action } from "@ngrx/store";
import { WorkRecord } from "src/app/core/models/work-record.model";

export const FETCH = '[WORKRECORDS] Fetch data';
export const SET_WORKRECORD = '[WORKRECORDS] Set work record';
export const ADD_WORKRECORD = '[WORKRECORDS] Add work record';
export const UPDATE_WORKRECORD = '[WORKRECORDS] Update work record';
export const DELETE_WORKRECORD = '[WORKRECORDS] Delete work record';
export const CHECK_WORKDAY = '[WORKRECORDS] Check workday';
export const SET_WORKDAY = '[WORKRECORDS] Set workday';

export class Fetch implements Action {
    readonly type = FETCH;
    constructor(public payload: { id: string, navigate: boolean}) { }
}

export class SetWorkRecords implements Action {
    readonly type = SET_WORKRECORD;
    constructor(public payload: WorkRecord[]) { }
}

export class AddWorkRecord implements Action {
    readonly type = ADD_WORKRECORD;
    constructor(public payload: {workRecord: WorkRecord, navigate: boolean}) { }
}

export class UpdateWorkRecord implements Action {
    readonly type = UPDATE_WORKRECORD;
    constructor(public payload: { workRecord: WorkRecord, navigate: boolean }) { }
}

export class DeleteWorkRecord implements Action {
    readonly type = DELETE_WORKRECORD;
    constructor(public payload: { workRecord: WorkRecord, navigate: boolean }) { }
}

export class CheckWorkday implements Action {
    readonly type = CHECK_WORKDAY;
}

export class SetWorkday implements Action {
    readonly type = SET_WORKDAY;
    constructor(public payload: boolean) { }
}

export type WorkRecordsActions = Fetch | SetWorkRecords | AddWorkRecord | UpdateWorkRecord | DeleteWorkRecord | CheckWorkday | SetWorkday ;
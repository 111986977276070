import { Accounts } from "../../interfaces/account.types";
import { AuthData } from "../../models/auth-data.model";
import { Employee } from "../../models/employee.model";
import * as AuthActions from "./auth.actions";

export interface AuthState {
    account?: Accounts,
    authData?: AuthData,
    alias?: Accounts
}

const initialState: AuthState = {
    account: undefined,
    authData: undefined,
    alias: undefined
};

export function authReducer(state: AuthState = initialState, action: AuthActions.AuthActions): AuthState {

    switch(action.type) {
        case AuthActions.FETCH_ACCOUNT:
            return {
                ...state,
                authData: action.payload,
                alias: undefined,
            }
        case AuthActions.REFRESH_AUTHDATA:
            return {
                ...state,
                authData: action.payload,
            }
        case AuthActions.LOGIN:
            return {
                ...state,
                account: action.payload,
                alias: undefined,
            }
        case AuthActions.LOGIN_FAILED:
            return {
                ...state,
                account: undefined,
                authData: undefined,
                alias: undefined,
            }
        case AuthActions.LOGOUT:
            return {
                ...state,
                account: undefined,
                authData: undefined,
                alias: undefined,
            }
        case AuthActions.SESSION_LOGOUT:
            return {
                ...state,
                account: undefined,
                authData: undefined,
                alias: undefined,
            }
        case AuthActions.SET_ALIAS:
            return {
                ...state,
                alias: action.payload,
            }
        case AuthActions.DELETE_ALIAS:
            return {
                ...state,
                alias: undefined,
            }
        default:
            return state;
    }

}
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { IAppState } from "../app/app.reducer";
import { HttpClient, HttpResponse } from '@angular/common/http';
import { catchError, interval, map, mapTo, of, startWith, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { utcToZonedTime } from 'date-fns-tz';
import { NGXLogger } from 'ngx-logger';
import * as TimeActions from './time.actions';
import * as UIActions from 'src/app/core/store/ui/ui.actions';
import * as WorkRecordActions from 'src/app/core/store/workRecords/workRecords.actions';


@Injectable()
export class TimeEffects {

    serverTime!: Date;

    update$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TimeActions.UPDATE),
            switchMap((action: TimeActions.Update) =>
                interval(1000).pipe(startWith(0), map(() => {
                    this.serverTime.setSeconds(this.serverTime.getSeconds(), 1000)
                    return new TimeActions.Assign(this.serverTime)
                }
                ))
            )
        )
    );

    assign$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TimeActions.ASSIGN),
            map(() => {
                return new WorkRecordActions.CheckWorkday();
            })
        )
    );

    fetch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TimeActions.FETCH),
            switchMap((action: TimeActions.Fetch) => {
                this.logger.info("Fetching server time");
                return this.http.get(environment.apiUrl + 'server/time', { observe: 'response' })
                    .pipe(
                        map((response: HttpResponse<any>) => {
                            const date = new Date(response.body.data);
                            const timezoneDate = utcToZonedTime(date, 'Europe/Berlin');
                            this.serverTime = timezoneDate;
                            return new TimeActions.Assign(this.serverTime);
                        }),
                        catchError((error) => {
                            const date = new Date();
                            const timezoneDate = utcToZonedTime(date, 'Europe/Berlin');
                            this.serverTime = timezoneDate;
                            this.store.dispatch(new UIActions.Error({ error: error, internal: false }));
                            return of(new TimeActions.Assign(this.serverTime));
                        })
                    )
            })
        )
    )

    constructor(
        private actions$: Actions,
        private store: Store<IAppState>,
        private http: HttpClient,
        private logger: NGXLogger
    ) { }
}
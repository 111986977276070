import { EAbsencesType } from "../enums/absences.enum";
import { ENotificationAbsenceType, ENotificationWorkRecordType } from "../enums/notification.enum";
import { INotificationAbsence, INotificationWorkRecord } from "../interfaces/notification.interface";
import { Notification } from "./notification.model";

export class NotificationWorkRecord extends Notification implements INotificationWorkRecord {

    private _workRecordId: string;
    private _workRecordType: ENotificationWorkRecordType;
    private _workRecordDate: Date;

    constructor(notification: INotificationWorkRecord) {
        super(notification)
        this._workRecordId = notification.workRecordId;
        this._workRecordType = notification.workRecordType;
        this._workRecordDate = notification.workRecordDate;
    }

    get workRecordId(): string {
        return this._workRecordId;
    }
    set workRecordId(value: string) {
        this._workRecordId = value;
    }

    get workRecordType(): ENotificationWorkRecordType {
        return this._workRecordType;
    }
    set workRecordType(value: ENotificationWorkRecordType) {
        this._workRecordType = value;
    }

    get workRecordDate(): Date {
        return this._workRecordDate;
    }

    set workRecordDate(date: Date) {
        this._workRecordDate = date;
    }

}
import { ErrorHandler, NgModule, isDevMode } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { FontAwesomeModule, FaIconLibrary} from "@fortawesome/angular-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { appReducer } from './core/store/app/app.reducer';
import { AuthEffects } from './core/store/auth/auth.effects';
import { environment } from 'src/environments/environment';
import { SharedModule } from './shared/shared.module';
import { UsersEffects } from './core/store/users/users.effects';
import { WorkRecordsEffects } from 'src/app/core/store/workRecords/workRecords.effects';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AuthGuard } from './core/guards/auth.guard';
import { AuthInterceptorService } from './core/interceptor/auth-interceptor.service';
import { TimeEffects } from './core/store/time/time.effects';
import { AbsencesEffects } from './core/store/absences/absences.effects'
import { UIEffects } from './core/store/ui/ui.effects';
import { NotificationsEffects } from './core/store/notifications/notifications.effects';
import { SettingsEffects } from "./core/store/settings/settings.effects";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { LoggerModule, NgxLoggerLevel, TOKEN_LOGGER_WRITER_SERVICE } from "ngx-logger";
import { LoggerWriterService } from "./core/utils/logger-writer.service";
import { GlobalErrorHandler } from "./core/utils/error-handler.service";
import { ServiceWorkerModule } from '@angular/service-worker';
import { SharedService } from "./shared/services/shared.service";
import { SplashScreenComponent } from "./core/components/splash-screen/splash-screen.component";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    SplashScreenComponent
  ],
  imports: [
    TranslateModule,
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    IonicModule.forRoot({
      mode: "ios",
    }),
    AppRoutingModule,
    FontAwesomeModule,
    StoreModule.forRoot(appReducer),
    EffectsModule.forRoot([
      AuthEffects,
      UsersEffects,
      WorkRecordsEffects,
      TimeEffects,
      AbsencesEffects,
      SettingsEffects,
      UIEffects,
      NotificationsEffects
    ]),
    StoreDevtoolsModule.instrument({ logOnly: environment.production }),
    SharedModule,
    LoggerModule.forRoot({
      level: NgxLoggerLevel.TRACE,
      serverLogLevel: NgxLoggerLevel.OFF,
    },
    {
      writerProvider: {
        provide: TOKEN_LOGGER_WRITER_SERVICE,
        useClass: LoggerWriterService
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    AuthGuard,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },

    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    SharedService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, fab, far);
  }
}

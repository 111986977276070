import { Action } from "@ngrx/store";
import { Accounts } from "../../interfaces/account.types";
import { IWorkTimeScheme } from "../../interfaces/work-time-scheme.interface";
import { Employee } from "../../models/employee.model";

export const FETCH_ALL = '[Users] Fetch all';
export const FETCH_EMPLOYEES = '[Users] Fetch all employees';
export const FETCH_USER = '[Users] Fetch user';
export const SET_USERS = '[Users] Set users';
export const SET_EMPLOYEES = '[Users] Set employees';
export const ADD_USER = '[Users] Add user';
export const SET_USER = '[Users] Set User';
export const SET_NAVIGATION = '[Users] Set navigation';
export const ADD_WORKTIMESCHEME = '[Users] Add Work time scheme';
export const UPDATE_WORKTIMESCHEME = '[Users] Update Work time scheme';
export const UPDATE_USER = '[Users] Update user';
export const DELETE_USER = '[Users] Delete user';
export const SAVE = '[Users] Save data';

export class FetchAll implements Action {
    readonly type = FETCH_ALL;
}

export class FetchEmployees implements Action {
    readonly type = FETCH_EMPLOYEES;
}

export class FetchUser implements Action {
    readonly type = FETCH_USER;
    constructor(public payload: Accounts) { }
}

export class SetUsers implements Action {
    readonly type = SET_USERS;
    constructor(public payload: Accounts []) {}
}

export class SetEmployees implements Action {
    readonly type = SET_EMPLOYEES;
    constructor(public payload: Accounts[]) { }
}

export class SetUser implements Action {
    readonly type = SET_USER;
    constructor(public payload: Accounts) { }
}

export class SetNavigation implements Action {
    readonly type = SET_NAVIGATION;
    constructor(public payload: { path: string, backNavigation: boolean }) { }
}

export class AddUser implements Action {
    readonly type = ADD_USER;
    constructor(public payload: Accounts) { }
}

export class AddWorkTimeScheme implements Action {
    readonly type = ADD_WORKTIMESCHEME;
    constructor(public payload: { item: IWorkTimeScheme, navigate: boolean }) {}
}

export class UpdateWorkTimeScheme implements Action {
    readonly type = UPDATE_WORKTIMESCHEME;
    constructor(public payload: IWorkTimeScheme) { }
}

export class UpdateUser implements Action {
    readonly type = UPDATE_USER;
    constructor(public payload: { id: string, updatedUser: Accounts}) { }
}

export class DeleteUser implements Action {
    readonly type = DELETE_USER;
    constructor(public payload: string) { }
}

export class Save implements Action {
    readonly type = SAVE;
}

export type UsersActions = FetchAll | FetchEmployees | FetchUser | SetUsers | SetUser | SetEmployees | SetNavigation | AddUser | AddWorkTimeScheme | UpdateWorkTimeScheme | UpdateUser | DeleteUser | Save;

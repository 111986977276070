import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { IAppState } from "./core/store/app/app.reducer";
import { AlertController, NavController, Platform } from "@ionic/angular";
import { EMessageType } from "./core/enums/message-type.enum";
import { Observable, Subscription, combineLatest } from "rxjs";
import { UIState } from "./core/store/ui/ui.reducer";
import { AuthState } from "./core/store/auth/auth.reducer";
import { LanguageService } from "./shared/services/language.service";
import { NGXLogger } from "ngx-logger";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { SharedService } from "./shared/services/shared.service";
import * as AuthActions from "./core/store/auth/auth.actions";
import * as UIActions from "./core/store/ui/ui.actions";
import * as TimerActions from "./core/store/time/time.actions";
import * as SettingsActions from "./core/store/settings/settings.action";
@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  alertTitle = "";
  alertMessage = "";
  public supportFile = false;
  public isLoading = false;
  private alertShown = false;
  private messageType!: EMessageType;
  private combinedStates$!: Observable<[any, any]>;
  private combinedStatesSubscription$!: Subscription;
  public authorized: boolean = false;
  private isBackground = false;
  public showSplash = true;

  constructor(
    private store: Store<IAppState>,
    private alertController: AlertController,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private logger: NGXLogger,
    private platform: Platform,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private router: Router,
    private navCtrl: NavController
  ) {}

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
    this.appIsBackground();
  }

  ngOnInit(): void {
    this.platform.ready().then(() => {
      this.logger.info("********** App started **********");

      this.languageService.setInitialAppLanguage();
      this.store.dispatch(new AuthActions.AutoLogin());
      this.store.dispatch(new TimerActions.Fetch());
      this.store.dispatch(new SettingsActions.Fetch());
      this.store.dispatch(new SettingsActions.FetchLogs());

      this.combinedStates$ = combineLatest([
        this.store.select("account"),
        this.store.select("ui"),
      ]);

      this.combinedStatesSubscription$ = this.combinedStates$.subscribe(
        ([account, ui]: [AuthState, UIState]) => {
          this.showSplash = ui.showSplash;

          account.account
            ? (this.authorized = true)
            : (this.authorized = false);

          if (ui.message) {
            switch (ui.type) {
              case EMessageType.INFORMATION:
                this.alertTitle = this.translateService.instant(
                  "CORE.MESSAGES.INFORMATION"
                );
                this.messageType = EMessageType.INFORMATION;
                break;
              case EMessageType.WARNING:
                this.alertTitle = this.translateService.instant(
                  "CORE.MESSAGES.WARNING"
                );
                this.messageType = EMessageType.WARNING;
                break;
              case EMessageType.ERROR:
                this.alertTitle = this.translateService.instant(
                  "CORE.MESSAGES.ERROR"
                );
                this.messageType = EMessageType.ERROR;
                break;
            }

            this.supportFile = ui.supportFile;

            this.alertMessage = ui.message;

            if (!this.alertShown && !ui.internal) {
              this.presentAlert();
            }
          }

          if (ui.loading) {
            this.isLoading = true;
          } else {
            this.isLoading = false;
          }
        }
      );

      this.route.queryParams.subscribe((params: Params) => {
        this.systemDateHandler(params);
      });
    });
  }

  ngOnDestroy(): void {
    if (this.combinedStatesSubscription$) {
      this.combinedStatesSubscription$.unsubscribe();
    }
  }

  async presentAlert() {
    this.alertShown = true;
    const alertOptions: any = {
      header: this.alertTitle,
      subHeader: this.supportFile ? this.alertMessage : "",
      message: this.supportFile
        ? this.translateService.instant("CORE.MESSAGES.NOTE")
        : this.alertMessage,
      cssClass: this.getAlertCss(),
      backdropDismiss: false,
      buttons: this.supportFile
        ? [
            {
              text: this.translateService.instant("CORE.ACTIONS.CANCEL"),
              role: "cancel",
              handler: () => {
                this.alertShown = false;
                this.store.dispatch(new UIActions.ResetMessage());
              },
            },
            {
              text: this.translateService.instant("CORE.ACTIONS.SEND"),
              role: "confirm",
              handler: (data: any) => {
                const enteredText = data.commentSection;
                this.alertShown = false;
                this.store.dispatch(new UIActions.ResetMessage());
                this.store.dispatch(new SettingsActions.SendLogs(enteredText));
              },
            },
          ]
        : [
            {
              text: this.translateService.instant("CORE.ACTIONS.OK"),
              role: "confirm",
              handler: () => {
                this.alertShown = false;
                this.store.dispatch(new UIActions.ResetMessage());
              },
            },
          ],
    };
    if (this.supportFile) {
      alertOptions.inputs = [
        {
          name: "commentSection",
          type: "textarea",
          placeholder: this.translateService.instant("CORE.MESSAGES.SUPPORT_FILE_INPUT_PLACEHOLDER"),
          attributes: {
            minlength: 0,
            maxlength: 255,
          },
        },
      ];
    }
    const alert = await this.alertController.create(alertOptions);
    await alert.present();
  }

  private getAlertCss(): string[] {
    let css: string[] = ["alert-app-messages"];

    switch (this.messageType) {
      case EMessageType.INFORMATION:
        css.push("alert-info-message");
        break;
      case EMessageType.WARNING:
        css.push("alert-warning-message");
        break;
      case EMessageType.ERROR:
        css.push("alert-error-message");
        break;
    }

    return css;
  }

  private systemDateHandler(params: Params): void {
    if (params["systemDate"]) {
      const systemDate = new Date(params["systemDate"]);
      if (isNaN(systemDate.getTime())) {
        this.store.dispatch(
          new UIActions.Error({
            error: `Invalid system date value "${params["systemDate"]}" in router query param`,
            internal: false,
          })
        );
      } else {
        if (systemDate.getFullYear() < 2020) {
          systemDate.setFullYear(2020);
        }
        this.logger.debug(
          `Set new system date with router query param as ${systemDate.toISOString()}`
        );
        this.sharedService.serverTime = systemDate;
      }
    }
  }

  appIsBackground() {

    if (document.hidden) {
      this.store.dispatch(new UIActions.ShowSplash());
      this.isBackground = true;
    } else {
      if(this.isBackground) {
        this.isBackground = false;
        this.store.dispatch(new TimerActions.Fetch());
        this.store.dispatch(new UIActions.StopSplash());
      }
    }
  }
}

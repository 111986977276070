import * as TimeActions from "./time.actions";

export interface TimeState {
    time: Date
}

const initialState: TimeState = {
    time: new Date(),
};

export function timeReducer(state: TimeState = initialState, action: TimeActions.TimeActions): TimeState {

    switch (action.type) {
        case TimeActions.ASSIGN:
            return {
                ...state,
                time: action.payload,
            }
        default:
            return state;
    }

}
import { IAbsence } from "../interfaces/absence.interface";
import { IEmployee } from "../interfaces/employee.interface";
import { IGenericPerson } from "../interfaces/generic-person.interface";
import { IWorkTimeScheme } from "../interfaces/work-time-scheme.interface";
import { Absence } from "./absence.model";
import { Person } from "./person.model";

export class Employee extends Person implements IEmployee {

    private _workTimeSchemes: IWorkTimeScheme[];
    private _absences: Absence[];

    constructor(person: IGenericPerson) {
        super(person);
        this._workTimeSchemes = person.workTimeSchemes ? person.workTimeSchemes : [];
        this._absences = person.absences ? person.absences : [];
    }

    get workTimeSchemes(): IWorkTimeScheme [] {
        return this._workTimeSchemes;
    }

    set workTimeSchemes(value: IWorkTimeScheme []) {
        this._workTimeSchemes = value;
    }

    get absences(): Absence[] {
        return this._absences;
    }

    set absences(value: Absence[]) {
        this._absences = value;
    }
}
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { catchError, throwError } from "rxjs";
import { IAuthData } from "src/app/core/interfaces/auth-response.interface";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  constructor(
    private translateService: TranslateService,
    private http: HttpClient
  ) {}

  login(email: string, password: string) {
    return this.http
      .post<IAuthData>(environment.apiUrl + "oauth/token", {
        grant_type: "password",
        client_id: environment.client_id,
        client_secret: environment.client_secret,
        username: email,
        password: password,
      })
      .pipe(catchError(this.handleError));
  }

  refreshToken(refreshToken: string) {
    return this.http.post<any>(
      environment.apiUrl + "oauth/token",
      {
        grant_type: "refresh_token",
        client_id: environment.client_id,
        client_secret: environment.client_secret,
        refresh_token: refreshToken,
      },
      { observe: "response" }
    );
  }

  private handleError(errorRes: HttpErrorResponse) {
    let errorMessage = "Ein unbekannter Fehler ist aufgetreten!";

    if (!errorRes.error || !errorRes.error.error) {
      return throwError(() => new Error(errorMessage));
    }

    switch (errorRes.error.error) {
      case "invalid_grant":
        errorMessage = this.translateService.instant(
          "CORE.MESSAGES.ERRORS.INCORRECT_CREDENTIALS"
        );
        break;
      case "Unauthenticated.":
        errorMessage = this.translateService.instant(
          "CORE.MESSAGES.ERRORS.UNAUTHORIZED_OR_INACTIVE"
        );
        break;
      case "invalid_request":
        errorMessage = this.translateService.instant("CORE.MESSAGES.ERRORS.SESSION_EXPIRED");
        break;
    }
    return throwError(() => new Error(errorMessage));
  }
}

<div class="container">
  <div class="container-inner">
    <div class="app-logo fade-in">
      <img alt="" src="../../../../assets/images/baerenkinder_logo.png">
    </div>
    <div class="app-label">
      <h1>{{appName}}</h1>
    </div>
    <ion-spinner name="dots"></ion-spinner>
  </div>
</div>>
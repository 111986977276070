import { ActionReducerMap } from '@ngrx/store';
import * as fromAuth from '../auth/auth.reducer';
import * as fromUsers from '../users/users.reducer';
import * as fromUI from '../ui/ui.reducer';
import * as fromSettings from "../settings/settings.reducer";
import * as fromTime from '../time/time.reducer';
import * as fromWorkRecords from 'src/app/core/store/workRecords/workRecords.reducer';
import * as fromAbsences from '../absences/absences.reducer';
import * as fromNotifications from '../notifications/notifications.reducer';
export interface IAppState {
    account: fromAuth.AuthState;
    users: fromUsers.UsersState;
    ui: fromUI.UIState;
    settings: fromSettings.SettingsState;
    time: fromTime.TimeState;
    workRecords: fromWorkRecords.WorkRecordsState;
    absences: fromAbsences.AbsencesState;
    notifications: fromNotifications.NotificationsState
}

export const appReducer: ActionReducerMap<IAppState, any> = {
    account: fromAuth.authReducer,
    users: fromUsers.usersReducer,
    ui: fromUI.uiReducer,
    settings: fromSettings.settingsReducer,
    time: fromTime.timeReducer,
    workRecords: fromWorkRecords.workRecordsReducer,
    absences: fromAbsences.absencesReducer,
    notifications: fromNotifications.notificationReducer,
}

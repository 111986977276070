export enum ENotificationStatus {
    UNREAD,
    READ
}

export enum ENotificationAbsenceType {
    CREATED,
    APPROVED,
    REJECTED,
    DELETED,
    DATE,
    NOTICE
}

export enum ENotificationWorkRecordType {
    CREATED,
    DELETED,
    UPDATED,
}
import { Absence } from "../../models/absence.model";
import * as AbsencesActions from './absences.actions';

export interface AbsencesState {
    absences: Absence [],
}

const initialState: AbsencesState = {
    absences: []
}

export function absencesReducer(state = initialState, action: AbsencesActions.AbsenceActions): AbsencesState {

    switch (action.type) {
        case AbsencesActions.SET_ABSENCES:
            return {
                ...state,
                absences: [...action.payload]
            }
        default:
            return state;
    }
}
import { Action } from "@ngrx/store";
import { ISettings } from "../../interfaces/settings.interface";
import { INGXLoggerMetadata } from "ngx-logger";

export const SET = "[SETTINGS] Set Settings";
export const FETCH = "[SETTINGS] Fetch Settings";
export const SET_LANGUAGE = "[SETTINGS] Set language";
export const TOGGLE_TEAM_CALENDAR = "[SETTINGS] Toggle Team calendar";
export const SAVE_LOGS = "[SETTINGS] Save logs in locale store";
export const SET_LOGS = "[SETTINGS] Set logs";
export const FETCH_LOGS = "[SETTINGS] Fetch logs from locale store";
export const ADD_LOG = "[SETTINGS] Add new log message";
export const SEND_LOGS = "[SETTINGS] Send logs to server";
export const RESET_LOGS = "[SETTINGS] Reset logs from storage";

export class Set implements Action {
  readonly type = SET;
  constructor(public payload: ISettings) { }
}

export class Fetch implements Action {
  readonly type = FETCH;
}
export class SetLanguage implements Action {
  readonly type = SET_LANGUAGE;
  constructor(public payload: string) { }
}
export class ToggleTeamCalendar implements Action {
  readonly type = TOGGLE_TEAM_CALENDAR;
  constructor(public payload: boolean) { }
}
export class SaveLogs implements Action {
  readonly type = SAVE_LOGS;
}

export class SetLogs implements Action {
  readonly type = SET_LOGS;
  constructor(public payload: INGXLoggerMetadata[]) { }
}

export class FetchLogs implements Action {
  readonly type = FETCH_LOGS;
}

export class AddLog implements Action {
  readonly type = ADD_LOG;
  constructor(public payload: INGXLoggerMetadata) { }
}

export class SendLogs implements Action {
  readonly type = SEND_LOGS;
  constructor(public payload: string) {}
}

export class ResetLogs implements Action {
  readonly type = RESET_LOGS;
}




export type settingsActions = Set | Fetch | SetLanguage | ToggleTeamCalendar | SaveLogs | SetLogs | FetchLogs | AddLog | SendLogs | ResetLogs ;

import { EUserGender } from "../enums/gender.enum";
import { EPasswordReset } from "../enums/password-reset.enum";
import { EUserRole } from "../enums/role.enum";
import { EUserStatus } from "../enums/user-status.enum";
import { IGenericPerson } from "../interfaces/generic-person.interface";
import { IPerson } from "../interfaces/person.interface";

export class Person implements IPerson {

    private _userId: string;
    private _lastName: string;
    private _firstName: string;
    private _gender: EUserGender;
    private _role: EUserRole;
    private _email: string;
    private _phone: string;
    private _address: string;
    private _houseNr: string;
    private _zipCode: string;
    private _city: string;
    private _entryDate: Date | undefined;
    private _status: EUserStatus;
    private _passwordReset: EPasswordReset;
    private _exitDate: Date | undefined;

    constructor(person: IGenericPerson) {
        this._userId = person.userId;
        this._lastName = person.lastName;
        this._firstName = person.firstName;
        this._gender = person.gender!;
        this._role = person.role!;
        this._email = person.email;
        this._phone = person.phone;
        this._address = person.address;
        this._houseNr = person.houseNr;
        this._zipCode = person.zipCode;
        this._city = person.city;
        this._entryDate = person.entryDate ? new Date(person.entryDate) : undefined;
        this._status = person.status!;
        this._passwordReset = person.passwordReset!;
        this._exitDate = person.exitDate ? new Date(person.exitDate) : undefined;
    }

    get userId(): string {
        return this._userId;
    }

    set userId(value: string) {
        this._userId = value;
    }

    get lastName(): string {
        return this._lastName;
    }

    set lastName(value: string) {
        this._lastName = value;
    }

    get firstName(): string {
        return this._firstName;
    }

    set firstName(value: string) {
        this._firstName = value;
    }

    get gender(): number {
        return this._gender;
    }

    set gender(value: number) {
        this._gender = value;
    }

    get role(): EUserRole {
        return this._role;
    }

    set role(value: EUserRole) {
        this._role = value;
    }

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }

    get phone(): string {
        return this._phone;
    }

    set phone(value: string) {
        this._phone = value;
    }

    get address(): string {
        return this._address;
    }

    set address(value: string) {
        this._address = value;
    }

    get houseNr(): string {
        return this._houseNr;
    }

    set houseNr(value: string) {
        this._houseNr = value;
    }

    get zipCode(): string {
        return this._zipCode;
    }

    set zipCode(value: string) {
        this._zipCode = value;
    }

    get city(): string {
        return this._city;
    }

    set city(value: string) {
        this._city = value;
    }

    get entryDate(): Date | undefined {
        return this._entryDate;
    }

    set entryDate(value: Date | undefined) {
        this._entryDate = value;
    }

    get status(): EUserStatus {
        return this._status;
    }

    set status(value: EUserStatus) {
        this._status = value;
    }

    get passwordReset(): EPasswordReset {
        return this._passwordReset;
    }

    set passwordReset(value: EPasswordReset) {
        this._passwordReset = value;
    }

    get exitDate(): Date | undefined {
        return this._exitDate;
    }

    set exitDate(value: Date | undefined) {
        this._exitDate = value;
    }

    get fullName(): string {
        return this._firstName + ' ' + this._lastName;
    }
}
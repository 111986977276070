<ion-list #ionList [inset]="true">
  <ion-item-sliding *ngFor="let absence of absences; let last = last" (ionDrag)="itemDragged(ionList)">
    <ion-item [lines]="last ? 'none' : 'full'" (click)="openAbsence(absence)">
      <ion-text class="start-icon" [color]="getIconColor(absence)" slot="start">
        <fa-icon [icon]="getIconName(absence)"></fa-icon>
      </ion-text>
      <ion-label color="medium">
        <ion-text>
          <h2>{{ getAbsenceType(absence) }}</h2>
        </ion-text>
        <p>{{ absence.getEmployeeName() }}</p>
        <p>
          {{ getAbsenceDateRange(absence) }}<span class="ion-float-right">{{ getDurationCount(absence) }}</span>
        </p>
      </ion-label>
    </ion-item>
    <ion-item-options (ionSwipe)="swipeHandler($event, absence)" side="start">
      <ion-item-option
        (click)="approveAbsence(absence)"
        color="app-success"
        expandable
      >
        <ion-text color="light" slot="start"
          ><fa-icon icon="circle-check"></fa-icon
        ></ion-text>
        <ion-text color="light">{{
          "PAGES.ABSENT_RECORD.APPROVE" | translate
        }}</ion-text>
      </ion-item-option>
    </ion-item-options>
    <ion-item-options (ionSwipe)="swipeHandler($event, absence)" side="end">
      <ion-item-option (click)="openAbsence(absence)" color="medium" expandable>
        <ion-text color="light" slot="start"
          ><fa-icon icon="pen"></fa-icon
        ></ion-text>
        <ion-text color="light">{{
          "PAGES.ACCOUNT.EDIT" | translate
        }}</ion-text>
      </ion-item-option>
    </ion-item-options>
  </ion-item-sliding>
  <ion-item lines="none" class="ion-text-center" *ngIf="absences.length == 0">
    <ion-label color="medium">{{
      "PAGES.ABSENT_RECORD.NO_ENTRY_FOUND" | translate
    }}</ion-label>
  </ion-item>
</ion-list>

import { Action } from "@ngrx/store";
import { INotifications, NotificationTypes } from "../../interfaces/notification.interface";

export const FETCH = '[NOTIFICATION] Fetch notifications';
export const SET_NOTIFICATIONS = '[NOTIFICATION] Set notifications';
export const ADD_ABSENCE = '[NOTIFICATION] Add absence notification';
export const UPDATE_ABSENCE = '[NOTIFICATION] Update absence notification';
export const DELETE_ABSENCE = '[NOTIFICATION] Delete absence notification';
export const UPDATE_WORKRECORD = '[NOTIFICATION] Update workrecord notification';
export const DELETE_WORKRECORD = '[NOTIFICATION] Delete workrecord notification';

export class Fetch implements Action {
    readonly type = FETCH;
    constructor(public payload: string) { }
}

export class SetNotifications implements Action {
    readonly type = SET_NOTIFICATIONS;
    constructor(public payload: INotifications) { }
}

export class UpdateAbsenceNotification implements Action {
    readonly type = UPDATE_ABSENCE;
    constructor(public payload: NotificationTypes) { }
}

export class DeleteAbsenceNotification implements Action {
    readonly type = DELETE_ABSENCE;
    constructor(public payload: NotificationTypes) { }
}

export class UpdateWorkRecordNotification implements Action {
    readonly type = UPDATE_WORKRECORD;
    constructor(public payload: NotificationTypes) { }
}

export class DeleteWorkRecordNotification implements Action {
    readonly type = DELETE_WORKRECORD;
    constructor(public payload: NotificationTypes) { }
}


export type NotificationActions = Fetch | SetNotifications | UpdateAbsenceNotification | DeleteAbsenceNotification | UpdateWorkRecordNotification | DeleteWorkRecordNotification;
import { act } from "@ngrx/effects";
import { Accounts } from "../../interfaces/account.types";
import * as UsersActions from './users.actions';
import { Employee } from "../../models/employee.model";

export interface UsersState {
    users: Accounts [],
    employees: Accounts [],
    selectedUser: Accounts | undefined,
    path: string,
    backNavigation: boolean,
}

const initialState: UsersState = {
    users: [],
    employees: [],
    selectedUser: undefined,
    path: '',
    backNavigation: false
};

export function usersReducer(state = initialState, action: UsersActions.UsersActions): UsersState {

    switch (action.type) {
        case UsersActions.SET_USERS:
            return {
                ...state,
                users: [...action.payload],
                selectedUser: undefined,
            }
        case UsersActions.SET_EMPLOYEES:
            return {
                ...state,
                employees: [...action.payload]
            }
        case UsersActions.SET_USER:
            return {
                ...state,
                selectedUser: action.payload,
            }
        case UsersActions.SET_NAVIGATION:
            return {
                ...state,
                path: action.payload.path,
                backNavigation: action.payload.backNavigation,
            }
        case UsersActions.UPDATE_USER:
            const i1 = state.users.findIndex(user => user.userId === action.payload.id);
            const updatedUsers = [...state.users];
            updatedUsers[i1] = action.payload.updatedUser;
            return {
                ...state,
                users: updatedUsers,
                selectedUser: undefined
            }
        default:
            return state;
    }
}
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { IAppState } from "src/app/core/store/app/app.reducer";
import { TimeState } from "src/app/core/store/time/time.reducer";
import { IWorkTimeScheme } from "src/app/core/interfaces/work-time-scheme.interface";
import { Employee } from "src/app/core/models/employee.model";
import {
  addDays,
  addMinutes,
  addSeconds,
  addWeeks,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  formatISO,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  isWeekend,
  isWithinInterval,
  Locale,
  min,
  setHours,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import { IHoliday } from "src/app/core/interfaces/holiday.interface";
import { holidays } from "src/assets/static/holidays";
import { WorkRecord } from "src/app/core/models/work-record.model";
import { Accounts } from "src/app/core/interfaces/account.types";
import { IGenericPerson } from "src/app/core/interfaces/generic-person.interface";
import { AuthData } from "src/app/core/models/auth-data.model";
import { IAuthData } from "src/app/core/interfaces/auth-response.interface";
import { EUserStatus } from "src/app/core/enums/user-status.enum";
import { EPasswordReset } from "src/app/core/enums/password-reset.enum";
import { IWorkRecord } from "src/app/core/interfaces/work-record.interface";
import { EUserRole } from "src/app/core/enums/role.enum";
import { Parent } from "src/app/core/models/parent.model";
import { Admin } from "src/app/core/models/admin.model";
import { Observable, Subject, combineLatest, first } from "rxjs";
import { Absence } from "src/app/core/models/absence.model";
import { IAbsence } from "src/app/core/interfaces/absence.interface";
import {
  INotificationAbsence,
  INotificationWorkRecord,
  INotifications,
  NotificationTypes,
} from "src/app/core/interfaces/notification.interface";
import { NotificationAbsence } from "src/app/core/models/notification-absence.model";
import { NotificationWorkRecord } from "src/app/core/models/notification-workRecord.model";
import { Device } from "@capacitor/device";
import { Platform } from "@ionic/angular";
import { SettingsState } from "src/app/core/store/settings/settings.reducer";
import * as UIActions from "src/app/core/store/ui/ui.actions";
import * as WorkRecordActions from "src/app/core/store/workRecords/workRecords.actions";
import { TranslateService } from "@ngx-translate/core";
import { de, enGB, tr } from "date-fns/locale";
import { AbsencesState } from "src/app/core/store/absences/absences.reducer";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { EAbsenceStatus, EAbsencesType } from "src/app/core/enums/absences.enum";
import { Board } from "src/app/core/models/board.model";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  private serverTimeIntervalID: NodeJS.Timeout | undefined;
  private _serverTime!: Date;
  private _serverTime$ = new Subject<Date>();
  private absences: Absence[] = [];
  public deviceID: any;
  public deviceInfo: any;
  public deviceBatteryInfo: any;
  public deviceLanguageCode: any;
  public languageCode: string = "";
  private combinedElements$!: Observable<[SettingsState, AbsencesState]>;

  constructor(private store: Store<IAppState>, private platform: Platform, private translateService: TranslateService) {
    this.platform.ready().then(() => {
      this.getDeviceID().then((val) => {
        this.deviceID = val;
      });

      this.getDeviceInfo().then((val) => {
        this.deviceInfo = val;
      });

      this.getBatteryInfo().then((val) => {
        this.deviceBatteryInfo = val;
      });

      this.getDeviceLanguageCode().then((val) => {
        this.deviceLanguageCode = val;
      });
    });

    this.combinedElements$ = combineLatest([this.store.select("settings"), this.store.select("absences")]);

    this.combinedElements$.subscribe(([settingsState, absencesState]: [SettingsState, AbsencesState]) => {
      this.languageCode = settingsState.settings.language;
      this.absences = absencesState.absences;
    });

    this.store.select("time").subscribe((data: TimeState) => {
      this._serverTime = data.time;
      if (this._serverTime) {
        if (this.serverTimeIntervalID) {
          clearInterval(this.serverTimeIntervalID);
        }

        this.serverTimeIntervalID = setInterval(() => {
          this._serverTime = addSeconds(this._serverTime, 1);
          this._serverTime$.next(this._serverTime);
        }, 1000);
      }
    });
  }

  get serverTime(): Date {
    return new Date(this._serverTime);
  }

  set serverTime(date: Date) {
    this._serverTime = date;
  }

  getServerTime(): Observable<Date> {
    return this._serverTime$.asObservable();
  }

  generateGUID(): string {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  createUserFromInterface(account: IGenericPerson): Accounts {
    var user: any;
    switch (account.role) {
      case EUserRole.ADMINISTRATOR: {
        user = new Admin(account);
        break;
      }
      case EUserRole.BOARD: {
        user = new Board(account);
        break;
      }
      case EUserRole.MANAGER:
      case EUserRole.EMPLOYEE:
      case EUserRole.TRAINEE: {
        user = new Employee(account);
        break;
      }
      default: {
        user = new Parent(account);
      }
    }
    return user;
  }

  createUserFromAPI(data: any) {
    const genericUser: IGenericPerson = {
      userId: data.id.toString(),
      firstName: data.firstName,
      lastName: data.lastName,
      gender: data.gender,
      role: data.role,
      email: data.email,
      phone: data.phone,
      address: data.address,
      houseNr: data.houseNr,
      zipCode: data.zipCode,
      city: data.city,
      entryDate: data.entryDate ? this.convertUTCDateTimeToLocal(data.entryDate) : undefined,
      exitDate: data.exitDate ? this.convertUTCDateTimeToLocal(data.exitDate) : undefined,
      status: data.status,
      passwordReset: data.passwordReset,
    };

    if (data.work_time_schemes && data.work_time_schemes.length > 0) {
      const workTimeSchemes: IWorkTimeScheme[] = this.createWorkTimeSchemesFromAPI(data.work_time_schemes);
      genericUser.workTimeSchemes = workTimeSchemes;
    }

    if (data.absences && data.absences.length > 0) {
      const absences: Absence[] = [];

      data.absences.forEach((absence: any) => {
        absences.push(this.createAbsenceFromResponse(absence));
      });

      genericUser.absences = absences;
    }

    const user = this.createUserFromInterface(genericUser);

    return user;
  }

  createWorkRecordFromResponse(workRecord: any): WorkRecord {
    const workRecordFromResponse: IWorkRecord = {
      id: workRecord.id.toString(),
      uuid: workRecord.employee_id.toString(),
      editorId: workRecord.editor_id ? workRecord.editor_id.toString() : "",
      startTime: this.convertUTCDateTimeToLocal(workRecord.start_time),
      endTime: workRecord.end_time ? this.convertUTCDateTimeToLocal(workRecord.end_time) : undefined,
      comment: workRecord.comment ? workRecord.comment : undefined,
      commentEditor: workRecord.comment_editor ? workRecord.comment_editor : undefined,
      notified: workRecord.notified,
      status: workRecord.status,
      editor: workRecord.editor ? { firstName: workRecord.editor.firstName, lastName: workRecord.editor.lastName } : undefined,
    };

    var wR: WorkRecord;
    wR = new WorkRecord(workRecordFromResponse);
    return wR;
  }

  createNotificationsFromResponse(response: any): INotifications {
    const NOTIFICATIONS: INotifications = {
      absences: [],
      workRecords: [],
    };

    if (response.absences.length > 0) {
      for (let notification of response.absences) {
        let tmpAbsenceNotification: INotificationAbsence = {
          id: notification.id,
          uuid: notification.author_id,
          status: notification.status,
          recipient: notification.recipient_id,
          absenceId: notification.absence_id,
          absenceNotificationType: notification.absence_notification_type,
          absenceType: notification.absence_type,
          author: { firstName: notification.author.firstName, lastName: notification.author.lastName },
          created: this.convertUTCDateTimeToLocal(notification.created_at),
          startDate: this.convertUTCDateTimeToLocal(notification.start_date),
          endDate: this.convertUTCDateTimeToLocal(notification.end_date),
        };

        NOTIFICATIONS.absences.push(new NotificationAbsence(tmpAbsenceNotification));
      }
    }

    if (response.workrecords.length > 0) {
      for (let notification of response.workrecords) {
        let tmpAbsenceNotification: INotificationWorkRecord = {
          id: notification.id,
          uuid: notification.author_id,
          status: notification.status,
          recipient: notification.recipient_id,
          workRecordId: notification.workrecord_id,
          workRecordType: notification.workrecord_type,
          author: { firstName: notification.author.firstName, lastName: notification.author.lastName },
          created: this.convertUTCDateTimeToLocal(notification.created_at),
          workRecordDate: this.convertUTCDateTimeToLocal(notification.workrecord_date),
        };

        NOTIFICATIONS.workRecords.push(new NotificationWorkRecord(tmpAbsenceNotification));
      }
    }
    return NOTIFICATIONS;
  }

  createAbsenceFromResponse(absence: any): Absence {
    const absenceFromResponse: IAbsence = {
      id: absence.id.toString(),
      uuid: absence.employee_id.toString(),
      editorId: absence.editor_id ? absence.editor_id.toString() : "",
      startDate: this.convertUTCDateTimeToLocal(absence.start_date),
      endDate: this.convertUTCDateTimeToLocal(absence.end_date),
      comment: absence.comment ? absence.comment : undefined,
      commentEditor: absence.comment_editor ? absence.comment_editor : undefined,
      type: absence.type,
      notified: absence.notified,
      status: absence.status,
      employee: absence.employee ? { firstName: absence.employee.firstName, lastName: absence.employee.lastName } : undefined,
      editor: absence.editor ? { firstName: absence.editor.firstName, lastName: absence.editor.lastName } : undefined,
    };

    var tmpAbsence: Absence;
    tmpAbsence = new Absence(absenceFromResponse);
    return tmpAbsence;
  }

  createEmptyGenericPeople(): IGenericPerson {
    var person: IGenericPerson = {
      userId: this.generateGUID(),
      lastName: "",
      firstName: "",
      gender: undefined,
      role: undefined,
      email: "",
      phone: "",
      address: "",
      houseNr: "",
      zipCode: "",
      city: "",
      entryDate: this.getWorkingDay(new Date(this._serverTime)),
      status: EUserStatus.PENDING,
      passwordReset: EPasswordReset.CUSTOM,
      exitDate: undefined,
      workTimeSchemes: [],
    };

    return person;
  }

  createAuthDataForStore(authData: AuthData): IAuthData {
    const data: IAuthData = {
      tokenType: authData.tokenType,
      expiresIn: authData.expiresIn,
      accessToken: authData.accessToken,
      refreshToken: authData.refreshToken,
      tokenExpirationDate: authData.tokenExpirationDate,
    };
    return data;
  }

  createAuthDataFromInterface(authData: any): AuthData {
    const data = new AuthData({
      tokenType: authData.tokenType,
      expiresIn: authData.expiresIn,
      accessToken: authData.accessToken,
      refreshToken: authData.refreshToken,
      tokenExpirationDate: this.convertUTCDateTimeToLocal(authData.tokenExpirationDate),
    });
    return data;
  }

  createNotificationFromInterface(notification: NotificationTypes): NotificationTypes {
    let newNotification!: NotificationTypes;
    if (notification instanceof NotificationAbsence) {
      const tmp: INotificationAbsence = {
        id: notification.id,
        uuid: notification.uuid,
        status: notification.status,
        recipient: notification.recipient,
        created: notification.created,
        author: notification.author,
        absenceId: notification.absenceId,
        absenceNotificationType: notification.absenceNotificationType,
        absenceType: notification.absenceType,
        startDate: notification.startDate,
        endDate: notification.endDate,
      };

      newNotification = new NotificationAbsence(tmp);
    }

    if (notification instanceof NotificationWorkRecord) {
      const tmp: INotificationWorkRecord = {
        id: notification.id,
        uuid: notification.uuid,
        status: notification.status,
        recipient: notification.recipient,
        created: notification.created,
        author: notification.author,
        workRecordId: notification.workRecordId,
        workRecordType: notification.workRecordType,
        workRecordDate: notification.workRecordDate,
      };

      newNotification = new NotificationWorkRecord(tmp);
    }

    return newNotification;
  }

  createUserInterfaceFromModel(account: Accounts): IGenericPerson {
    var user: IGenericPerson = {
      userId: account.userId,
      lastName: account.lastName,
      firstName: account.firstName,
      gender: account.gender,
      role: account.role,
      email: account.email,
      phone: account.phone,
      address: account.address,
      houseNr: account.houseNr,
      zipCode: account.zipCode,
      city: account.city,
      entryDate: account.entryDate,
      status: account.status,
      passwordReset: account.passwordReset,
      exitDate: account.exitDate ? account.exitDate : undefined,
    };

    if (account instanceof Employee) {
      user.workTimeSchemes = account.workTimeSchemes;
    }

    return user;
  }

  getAbsenceDateRange(absence: Absence): string {
    var date: string = "";

    const STARTDATE = format(absence.startDate, "EEE dd.MM.yy", { locale: this.getLanguageLocaleCode() });
    const ENDDATE = format(absence.endDate, "EEE dd.MM.yy", { locale: this.getLanguageLocaleCode() });

    if (isSameDay(absence.startDate, absence.endDate)) {
      return STARTDATE;
    }

    date = STARTDATE + " - " + ENDDATE;
    return date;
  }

  getAbsenceIconColor(type: EAbsencesType): string {
    var title = "";

    switch (type) {
      case 0:
        title = "app-success";
        break;
      case 1:
        title = "warning";
        break;
      case 2:
        title = "app-primary";
        break;
      case 3:
        title = "app-danger";
        break;
    }

    return title;
  }

  getAbsenceIconName(type: EAbsencesType): IconProp {
    var icon!: IconProp;

    switch (type) {
      case 0:
        icon = "umbrella-beach";
        break;
      case 1:
        icon = "business-time";
        break;
      case 2:
        icon = "award";
        break;
      case 3:
        icon = "briefcase-medical";
        break;
    }

    return icon;
  }

  getAbsenceStatusColor(status: EAbsenceStatus): string {
    var color: string = "";

    switch (status) {
      case 0:
        color = "app-primary";
        break;
      case 1:
        color = "app-success";
        break;
      case 2:
        color = "app-danger";
        break;
    }
    return color;
  }

  getAbsenceType(type: EAbsencesType): string {
    var name = "";

    switch (type) {
      case 0:
        name = this.translateService.instant("PAGES.ABSENT_RECORD.TITLE.VACATION");
        break;
      case 1:
        name = this.translateService.instant("PAGES.ABSENT_RECORD.TITLE.TIME_OFF");
        break;
      case 2:
        name = this.translateService.instant("PAGES.ABSENT_RECORD.TITLE.CONTINUING_EDUCATION");
        break;
      case 3:
        name = this.translateService.instant("PAGES.ABSENT_RECORD.TITLE.SICK_LEAVE");
        break;
      case 4:
        name = this.translateService.instant("PAGES.ABSENT_RECORD.TITLE.LEAVE");
        break;
    }

    return name;
  }

  getAbsenceStatus(status: EAbsenceStatus): string {
    var name = "";

    switch (status) {
      case 0:
        name = this.translateService.instant("PAGES.ABSENT_RECORD.STATUS.REQUESTED");
        break;
      case 1:
        name = this.translateService.instant("PAGES.ABSENT_RECORD.STATUS.APPROVED");
        break;
      case 2:
        name = this.translateService.instant("PAGES.ABSENT_RECORD.STATUS.REJECTED");
        break;
    }

    return name;
  }

  getAllDaysOfMonth(date: Date): Date[] {
    const DAYS_OF_MONTH: Date[] = [];

    let startDay = startOfMonth(date);
    let endDay = endOfMonth(date);

    while (!isSameDay(startDay, endDay)) {
      DAYS_OF_MONTH.push(new Date(startDay));
      startDay = addDays(startDay, 1);
    }

    DAYS_OF_MONTH.push(endDay);

    return DAYS_OF_MONTH;
  }

  highlightedAbsenceDates(dateString: string, type = -1, startDate = "", endDate = "") {
    const date = new Date(dateString);

    if (this.isDateHoliday(date)) {
      return {
        textColor: "#ffffff",
        backgroundColor: "#92949c",
      };
    }

    if (
      this.isDateInRangeOfOtherAbsenceType(date, EAbsencesType.VACATION) ||
      ((dateString == startDate || dateString == endDate) && type == EAbsencesType.VACATION)
    ) {
      return {
        textColor: "#ffffff",
        backgroundColor: "#59c28f",
      };
    }

    if (
      this.isDateInRangeOfOtherAbsenceType(date, EAbsencesType.OVERTIME) ||
      ((dateString == startDate || dateString == endDate) && type == EAbsencesType.OVERTIME)
    ) {
      return {
        textColor: "#ffffff",
        backgroundColor: "#ffca22",
      };
    }

    if (
      this.isDateInRangeOfOtherAbsenceType(date, EAbsencesType.TRAINING) ||
      ((dateString == startDate || dateString == endDate) && type == EAbsencesType.TRAINING)
    ) {
      return {
        textColor: "#ffffff",
        backgroundColor: "#4c8dff",
      };
    }

    if (
      this.isDateInRangeOfOtherAbsenceType(date, EAbsencesType.SICK_LEAVE) ||
      ((dateString == startDate || dateString == endDate) && type == EAbsencesType.SICK_LEAVE)
    ) {
      return {
        textColor: "#ffffff",
        backgroundColor: "#F54F29",
      };
    }

    return undefined;
  }

  isDayBookable(dateString: string = formatISO(new Date(this._serverTime)), newAbsence = true, id = "-1") {
    const date = new Date(dateString);
    return !isWeekend(date) && !this.isDateHoliday(date) && !this.isDateInRangeOfOtherAbsence(date, newAbsence, id);
  }

  private isDateInRangeOfOtherAbsence(date: Date, newAbsence: boolean, id: string): boolean {
    let highlight = false;

    if (this.absences.length == 0) {
      return highlight;
    }

    const CHECK_DATE = date.setHours(7);

    for (const absence of this.absences) {
      const START_DATE = absence.startDate.setHours(1);
      const END_DATE = absence.endDate.setHours(23);

      if (isSameDay(START_DATE, END_DATE) && isSameDay(START_DATE, CHECK_DATE)) {
        highlight = true;
      }

      if (
        isWithinInterval(CHECK_DATE, {
          start: START_DATE,
          end: END_DATE,
        })
      ) {
        highlight = true;

        if (!newAbsence && absence.id == id) {
          highlight = false;
        }
      }
    }
    return highlight;
  }

  private isDateInRangeOfOtherAbsenceType(date: Date, type: EAbsencesType): boolean {
    let highlight = false;

    if (this.absences.length == 0 || isWeekend(date)) {
      return highlight;
    }

    const CHECK_DATE = date.setHours(7);

    for (const absence of this.absences) {
      const START_DATE = absence.startDate.setHours(7);
      const END_DATE = absence.endDate.setHours(7);

      if (absence.type == type) {
        if (isSameDay(START_DATE, END_DATE) && isSameDay(START_DATE, CHECK_DATE)) {
          highlight = true;
        }

        if (
          isWithinInterval(CHECK_DATE, {
            start: START_DATE,
            end: END_DATE,
          })
        ) {
          highlight = true;
        }
      }
    }
    return highlight;
  }

  getLanguageCodeDateTime(): string {
    let code = "";

    switch (this.languageCode) {
      case "de":
        code = "de-DE";
        break;
      case "en":
        code = "en-GB";
        break;
      case "tr":
        code = "tr-TR";
        break;
    }

    return code;
  }

  getLanguageLocaleCode(): Locale {
    let code!: Locale;

    switch (this.languageCode) {
      case "de":
        code = de;
        break;
      case "en":
        code = enGB;
        break;
      case "tr":
        code = tr;
        break;
      default:
        code = de;
    }

    return code;
  }

  getLocalizedMonthAndYear(date: Date, withYear = true): string {
    let monthName = date.toLocaleString(this.languageCode, { month: "long" });
    let year = date.getFullYear();
    let monthNameAndYear = withYear ? monthName + " " + year : monthName;
    return monthNameAndYear;
  }

  getLocalizedDateWithDayName(date: Date): string {
    var options = { weekday: "long", year: "numeric", month: "2-digit", day: "2-digit" } as const;
    return date.toLocaleDateString(this.languageCode, options);
  }

  getLocalizedDayName(weekday: number, format: any): { day: string; weekday: number } {
    let date = new Date(this._serverTime);

    while (date.getDay() !== weekday) {
      date.setDate(date.getDate() + 1);
    }
    let dayName = {
      day: date.toLocaleDateString(this.getLanguageCodeDateTime(), { weekday: format }),
      weekday: date.getDay(),
    };
    return dayName;
  }

  convertUTCDateTimeToLocal(utcTime: string) {
    var date;
    if (utcTime.includes("Z")) {
      date = new Date(utcTime);
    } else {
      date = new Date(utcTime + " UTC");
    }
    return date;
  }

  canStartWorkRecords(wts: IWorkTimeScheme[]): boolean {
    let workTimeSchemes = [...wts];

    workTimeSchemes.sort((a, b) => a.startDate.getTime() - b.startDate.getTime());

    let startDate = new Date(workTimeSchemes[0].startDate);
    startDate.setHours(7);

    return this._serverTime.getTime() >= startDate.getTime();
  }

  getWorkRecordsStartDate(wts: IWorkTimeScheme[]): Date {
    let workTimeSchemes = [...wts];

    workTimeSchemes.sort((a, b) => a.startDate.getTime() - b.startDate.getTime());

    return workTimeSchemes[0].startDate;
  }

  getCarryOverVacationDays(wts: IWorkTimeScheme[]): number {
    let workTimeSchemes = [...wts];

    workTimeSchemes.sort((a, b) => a.startDate.getTime() - b.startDate.getTime());

    return workTimeSchemes[0].carryover_vacations ? workTimeSchemes[0].carryover_vacations : 0;
  }

  currentWorkTimeScheme(wts: IWorkTimeScheme[], date: Date = this._serverTime): IWorkTimeScheme {
    let workTimeSchemes = [...wts];

    workTimeSchemes.sort((a, b) => a.startDate.getTime() - b.startDate.getTime());

    const CHECK_DATE = new Date(date);
    let currentWorkTimeScheme: IWorkTimeScheme | undefined = undefined;

    for (const item of workTimeSchemes) {
      // Check if the item's startDate is earlier or equal to today
      if (item.startDate && item.endDate && item.startDate <= CHECK_DATE && CHECK_DATE <= item.endDate) {
        currentWorkTimeScheme = item;
      }

      if (item.startDate && !item.endDate && item.startDate <= CHECK_DATE) {
        currentWorkTimeScheme = item;
      }
    }

    if (workTimeSchemes.length == 0) {
      throw new Error("No work time scheme available! Please update the employee data.");
    } else if (!currentWorkTimeScheme) {
      currentWorkTimeScheme = workTimeSchemes[0];
    }
    return currentWorkTimeScheme!;
  }

  isDateHoliday(date: Date): IHoliday | undefined {
    const holidays: IHoliday[] = this.getHolidays(date.getFullYear().toString());

    const holidayList = holidays.find((day) => day.date.getMonth() === date.getMonth() && day.date.getDate() === date.getDate());
    return holidayList;
  }

  getCurrentBallance(employee: Employee, workRecords: WorkRecord[]): number {
    let startDate = new Date(this.getWorkRecordsStartDate(employee.workTimeSchemes));
    let today = new Date(this._serverTime);
    let shouldHours = 0;
    let isHours = 0;

    if (employee.workTimeSchemes[0].carryover_overtime) {
      isHours += employee.workTimeSchemes[0].carryover_overtime * 60;
    }

    const days: Date[] = [];

    while (!isSameDay(startDate, today)) {
      days.push(new Date(startDate));
      startDate = addDays(startDate, 1);
    }

    days.forEach((day: Date) => {
      isHours += this.isHoursToday(day, workRecords);
      shouldHours += this.shouldHoursToday(employee, day, true);
    });

    return isHours - shouldHours;
  }

  getTotalBalance(employee: Employee, workRecords: WorkRecord[]): number {
    let shouldHours = 0;
    let isHours = this.getCurrentBallance(employee, workRecords);
    let today = new Date(this._serverTime);
    let endDate = addDays(addWeeks(today, 4), 1);

    while (!isSameDay(today, endDate)) {
      var workTimeScheme = this.currentWorkTimeScheme(employee.workTimeSchemes, today);
      const totalWorkingDays = Object.values(workTimeScheme.workingDays).filter(Boolean).length;
      var totalWorkingMinutes = (workTimeScheme.workingHours! * 60) / totalWorkingDays;

      const IS_DATE_HOLIDAY = this.isDateHoliday(today);
      const IS_ABSENCE = this.isDateAbsence(today);

      if (!IS_DATE_HOLIDAY && !isWeekend(today) && IS_ABSENCE && IS_ABSENCE.type == EAbsencesType.OVERTIME) {
        shouldHours = shouldHours + totalWorkingMinutes;
      }
      today = addDays(today, 1);
    }

    return isHours - shouldHours;
  }

  shouldHoursToday(employee: Employee, date: Date, timeAccount = false): number {
    var workTimeScheme = this.currentWorkTimeScheme(employee.workTimeSchemes, date);
    const START_DATE = this.getWorkRecordsStartDate(employee.workTimeSchemes);
    return this.calculateDailyShouldWorkingMinutes(workTimeScheme, date, START_DATE, timeAccount);
  }

  shouldHoursWeek(employee: Employee, date: Date): number {
    const START_DATE = this.getWorkRecordsStartDate(employee.workTimeSchemes);
    var minutes = 0;

    const monday = startOfWeek(date, { weekStartsOn: 1 });
    const sunday = endOfWeek(date, { weekStartsOn: 1 });

    const weekdays = eachDayOfInterval({ start: monday, end: sunday }).filter((date) => !isWeekend(date));

    weekdays.forEach((day: Date) => {
      var workTimeScheme = this.currentWorkTimeScheme(employee.workTimeSchemes, day);
      minutes += this.calculateDailyShouldWorkingMinutes(workTimeScheme, day, START_DATE);
    });

    return minutes;
  }

  shouldHoursMonths(employee: Employee, date: Date, timeAccount = false): number {
    const START_DATE = this.getWorkRecordsStartDate(employee.workTimeSchemes);
    var minutes = 0;

    const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    const weekdaysOfMonth = eachDayOfInterval({ start: startOfMonth, end: endOfMonth })
      .filter((date) => !isWeekend(date))
      .filter((date) => isSameMonth(date, startOfMonth));

    weekdaysOfMonth.forEach((day: Date) => {
      var workTimeScheme = this.currentWorkTimeScheme(employee.workTimeSchemes, day);
      minutes += this.calculateDailyShouldWorkingMinutes(workTimeScheme, day, START_DATE, timeAccount);
    });

    return minutes;
  }

  isHoursToday(date: Date, workRecords: WorkRecord[]) {
    const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
    const endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
    var todayWorkRecords: WorkRecord[] = [];

    workRecords.forEach((wr) => {
      const { startTime, endTime } = wr;
      if (endTime && startTime > startOfDay && endTime < endOfDay) {
        todayWorkRecords.push(wr);
      }

      if (!endTime && isSameDay(date, startTime)) {
        todayWorkRecords.push(wr);
      }
    });

    return this.calculateWorkingMinutes(todayWorkRecords);
  }

  isHoursWeek(date: Date, workRecords: WorkRecord[]): number {
    var totalWorkingMinutes = 0;
    const monday = startOfWeek(date, { weekStartsOn: 1 });
    const sunday = endOfWeek(date, { weekStartsOn: 1 });

    var weekWorkRecords: WorkRecord[] = [];

    workRecords.forEach((wr) => {
      const { startTime, endTime } = wr;
      if (endTime && startTime > monday && endTime < sunday) {
        weekWorkRecords.push(wr);
      }

      if (!endTime && isSameDay(date, startTime)) {
        weekWorkRecords.push(wr);
      }
    });

    var today = new Date(monday);

    while (!isSameDay(today, addDays(sunday, 1))) {
      const sameDayWorkRecords: WorkRecord[] = [];

      for (let wr of workRecords) {
        if (isSameDay(today, wr.startTime)) {
          sameDayWorkRecords.push(wr);
        }
      }

      totalWorkingMinutes = totalWorkingMinutes + this.calculateWorkingMinutes(sameDayWorkRecords);

      today = addDays(today, 1);
    }

    return totalWorkingMinutes;
  }

  isHoursMonth(date: Date, workRecords: WorkRecord[]): number {
    var totalWorkingMinutes = 0;
    const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59);

    var weekWorkRecords: WorkRecord[] = [];

    workRecords.forEach((wr) => {
      const { startTime, endTime } = wr;
      if (endTime && startTime > startOfMonth && endTime < endOfMonth) {
        weekWorkRecords.push(wr);
      }

      if (!endTime && isSameDay(date, startTime)) {
        weekWorkRecords.push(wr);
      }
    });

    var today = new Date(startOfMonth);

    while (!isSameDay(today, addDays(endOfMonth, 1))) {
      const sameDayWorkRecords: WorkRecord[] = [];

      for (let wr of workRecords) {
        if (isSameDay(today, wr.startTime)) {
          sameDayWorkRecords.push(wr);
        }
      }

      totalWorkingMinutes = totalWorkingMinutes + this.calculateWorkingMinutes(sameDayWorkRecords);

      today = addDays(today, 1);
    }
    return totalWorkingMinutes;
  }

  todayWorkRecords(date: Date, workRecords: WorkRecord[]) {
    const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
    const endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
    const nextDay = addDays(startOfDay, 1);
    var todayWorkRecords: WorkRecord[] = [];

    workRecords.forEach((wr) => {
      const { startTime, endTime } = wr;
      if (endTime && startTime > startOfDay && endTime < endOfDay) {
        todayWorkRecords.push(wr);
      }

      if (!endTime && startTime > startOfDay && startTime < nextDay) {
        todayWorkRecords.push(wr);
      }
    });

    todayWorkRecords = todayWorkRecords.sort((a, b) => {
      return a.startTime.getTime() - b.startTime.getTime();
    });

    return todayWorkRecords;
  }

  convertMinutesToHHMM(minutes: number) {
    let negativeValue = false;

    if (minutes < 0) {
      minutes *= -1;
      negativeValue = true;
    }

    const hours = Math.floor(minutes / 60);
    let minutesRemainder = minutes % 60;
    minutesRemainder = parseFloat(minutesRemainder.toFixed(0));
    let formattedHours = String(hours).padStart(2, "0");
    let formattedMinutes = String(minutesRemainder).padStart(2, "0");

    negativeValue ? (formattedHours = "-" + formattedHours) : formattedHours;

    return `${formattedHours}:${formattedMinutes}`;
  }

  checkStartedWorkRecord(date: Date, workRecords: WorkRecord[]): WorkRecord | undefined {
    var wr: WorkRecord | undefined = undefined;

    workRecords.forEach((w) => {
      const { startTime, endTime } = w;
      if (isSameDay(date, startTime) && !endTime) {
        wr = w;
      }
    });

    return wr;
  }

  getDateTimAsIso(date: Date): string {
    const isoDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
    return isoDate;
  }

  getLastWorkingDay() {
    const tmpDate = new Date(this.serverTime);

    if (tmpDate.getHours() < 7) {
      tmpDate.setDate(tmpDate.getDate() - 1);
    }

    while (this.isDateHoliday(tmpDate)) {
      tmpDate.setDate(tmpDate.getDate() - 1);
    }

    while (this.isDateInRangeOfOtherAbsence(tmpDate, true, "")) {
      tmpDate.setDate(tmpDate.getDate() - 1);
    }

    const day = tmpDate.getDay();
    var workingDay = new Date(this._serverTime);
    switch (day) {
      case 0:
        workingDay = new Date(tmpDate.setDate(tmpDate.getDate() - 2));
        break;
      case 6:
        workingDay = new Date(tmpDate.setDate(tmpDate.getDate() - 1));
        break;
      default:
        workingDay = tmpDate;
    }
    return workingDay;
  }

  isWorkingDay() {
    return this.isDayBookable();
  }

  getWorkingDay(date: Date) {
    const tmpDate = new Date(date);
    while (this.isDateHoliday(tmpDate)) {
      tmpDate.setDate(tmpDate.getDate() + 1);
    }
    const day = tmpDate.getDay();
    var workingDay = date;
    switch (day) {
      case 0:
        workingDay = new Date(tmpDate.setDate(tmpDate.getDate() + 1));
        break;
      case 6:
        workingDay = new Date(tmpDate.setDate(tmpDate.getDate() + 2));
        break;
      default:
        workingDay = tmpDate;
    }
    return workingDay;
  }

  private getHolidays(year: string): IHoliday[] {
    const h: IHoliday[] = [];
    const index = holidays.findIndex((yearHoliday) => yearHoliday.year === +year);
    if (index !== -1) {
      holidays[index].data.forEach((data) => {
        const date = new Date(+year, data.date.month, data.date.day);
        let day: IHoliday = {
          name: data.name,
          date: date,
        };
        h.push(day);
      });
    }
    return h;
  }

  getAbsenceDurationCount(startDate: Date, endDate: Date): number {
    let vacationDaysCount = 1;

    if (isSameDay(startDate, endDate)) {
      return vacationDaysCount;
    }

    while (!isSameDay(startDate, endDate)) {
      if (!isWeekend(startDate) && !this.isDateHoliday(startDate)) {
        vacationDaysCount = vacationDaysCount + 1;
      }
      startDate = addDays(startDate, 1);
    }

    return vacationDaysCount;
  }

  private createWorkTimeSchemesFromAPI(data: any[]): IWorkTimeScheme[] {
    const workTimeSchemes: IWorkTimeScheme[] = [];

    data.forEach((d, index) => {
      const workTimeScheme: IWorkTimeScheme = {
        id: d.id.toString(),
        uuid: d.employee_id.toString(),
        startDate: this.convertUTCDateTimeToLocal(d.start_date),
        endDate: d.end_date ? this.convertUTCDateTimeToLocal(d.end_date) : undefined,
        workingHours: d.working_hours,
        vacationDays: d.vacation_days,
        carryover_vacations: d.carryover_vacations,
        carryover_overtime: d.carryover_overtime,
        workingDays: {
          monday: d.monday,
          tuesday: d.tuesday,
          wednesday: d.wednesday,
          thursday: d.thursday,
          friday: d.friday,
        },
      };

      workTimeSchemes.push(workTimeScheme);
    });

    return workTimeSchemes;
  }

  public isDateAbsence(date: Date, absences: Absence[] | undefined = undefined): Absence | undefined {
    let a = undefined;
    let tmpAbsences: Absence[] = [];

    if (absences) {
      tmpAbsences = [...absences];
    } else {
      tmpAbsences = [...this.absences];
    }

    if (tmpAbsences.length > 0) {
      for (const absence of tmpAbsences) {
        let startDate = new Date(absence.startDate);
        let endDate = new Date(absence.endDate);
        let checkDate = new Date(date);

        if (isSameDay(startDate, endDate) && !a) {
          isSameDay(checkDate, startDate) && !this.isDateHoliday(date) && !isWeekend(date) ? (a = absence) : (a = undefined);
        } else {
          endDate = addDays(endDate, 1);
          while (!isSameDay(startDate, endDate) && !a) {
            isSameDay(checkDate, startDate) && !this.isDateHoliday(date) && !isWeekend(date) ? (a = absence) : (a = undefined);
            startDate = addDays(startDate, 1);
          }
        }
      }
    }
    return a;
  }

  private calculateDailyShouldWorkingMinutes(workingSchema: IWorkTimeScheme, date: Date, worRecordStartDate: Date, timeAccount = false) {
    const totalWorkingDays = Object.values(workingSchema.workingDays).filter(Boolean).length;
    var totalWorkingMinutes = (workingSchema.workingHours! * 60) / totalWorkingDays;
    const IS_DATE_HOLIDAY = this.isDateHoliday(date);
    let isDateAbsence = this.isDateAbsence(date);

    date.setHours(7);
    worRecordStartDate.setHours(7);

    const IS_BEFORE_START_DATE = isBefore(date, worRecordStartDate) && !isSameDay(date, worRecordStartDate);

    if (timeAccount == true && isDateAbsence && isDateAbsence.type == EAbsencesType.OVERTIME) {
      isDateAbsence = undefined;
    }

    if (IS_DATE_HOLIDAY || isDateAbsence != undefined || IS_BEFORE_START_DATE) {
      totalWorkingMinutes = 0;
    }

    switch (date.getDay()) {
      case 0:
        totalWorkingMinutes = 0;
        break;
      case 1:
        if (!workingSchema.workingDays.monday) {
          totalWorkingMinutes = 0;
        }
        break;
      case 2:
        if (!workingSchema.workingDays.tuesday) {
          totalWorkingMinutes = 0;
        }
        break;
      case 3:
        if (!workingSchema.workingDays.wednesday) {
          totalWorkingMinutes = 0;
        }
        break;
      case 4:
        if (!workingSchema.workingDays.thursday) {
          totalWorkingMinutes = 0;
        }
        break;
      case 5:
        if (!workingSchema.workingDays.friday) {
          totalWorkingMinutes = 0;
        }
        break;
      case 6:
        totalWorkingMinutes = 0;
        break;
    }

    return totalWorkingMinutes;
  }

  private calculateWorkingMinutes(workingRecords: WorkRecord[]): number {
    workingRecords.sort((a, b) => {
      return a.startTime.getTime() - b.startTime.getTime();
    });

    let sameDayWorkRecords: WorkRecord[] = [...workingRecords];
    let totalWorkingMinutes = 0;
    let pause = 0;
    let dailyWorkingMinutes: any = {};
    const now = new Date(this._serverTime);
    now.setSeconds(0);

    sameDayWorkRecords = this.setEndTimeForCurrentWorkRecord(sameDayWorkRecords, now);
    pause = this.calculatePauseBetweenWorkRecords(sameDayWorkRecords);

    for (const element of sameDayWorkRecords) {
      if (!this.isDayBookable(element.startTime.toISOString())) continue;

      const { startTime, endTime } = element;

      var workingMinutes = 0;

      startTime.setSeconds(0);

      const dateKey = startTime.toISOString().split("T")[0];

      if (!dailyWorkingMinutes[dateKey]) {
        dailyWorkingMinutes[dateKey] = [];
      }

      if (endTime) {
        endTime.setSeconds(0);
        workingMinutes = (endTime!.getTime() - startTime.getTime()) / (1000 * 60);
      } else if (isSameDay(startTime, now) && !isAfter(now, setHours(now, 21))) {
        workingMinutes = (now!.getTime() - startTime.getTime()) / (1000 * 60);
      }

      dailyWorkingMinutes[dateKey].push(workingMinutes);
    }

    for (const dateKey in dailyWorkingMinutes) {
      const dayWorkingMinutes = dailyWorkingMinutes[dateKey];

      const dayTotalMinutes = dayWorkingMinutes.reduce((total: number, minutes: number) => total + minutes, 0);

      let adjustedMinutes = dayTotalMinutes;
      if (dayTotalMinutes > 360 && dayTotalMinutes <= 540 && pause <= 30) {
        adjustedMinutes -= 30;
      } else if (dayTotalMinutes > 540 && pause <= 45) {
        adjustedMinutes -= 45;
      }

      totalWorkingMinutes += adjustedMinutes;
    }

    totalWorkingMinutes = totalWorkingMinutes > 600 ? 600 : totalWorkingMinutes;

    return Math.round(totalWorkingMinutes);
  }

  private setEndTimeForCurrentWorkRecord(workRecords: WorkRecord[], date: Date): WorkRecord[] {
    if (workRecords.length > 0 && isSameDay(workRecords[0].startTime, date)) {
      for (let i = 0; i < workRecords.length; i++) {
        if (workRecords[i].endTime == undefined) {
          const w: IWorkRecord = {
            id: workRecords[i].id,
            uuid: workRecords[i].uuid,
            editorId: workRecords[i].editorId,
            startTime: workRecords[i].startTime,
            endTime: date,
            comment: workRecords[i].comment,
            commentEditor: workRecords[i].commentEditor,
            notified: workRecords[i].notified,
            status: workRecords[i].status,
          };

          const tmpWR = new WorkRecord(w);
          workRecords[i] = tmpWR;
        }
      }
      workRecords = this.checkOverlappingWorkRecords(workRecords);
    }
    return workRecords;
  }

  private checkOverlappingWorkRecords(workRecords: WorkRecord[]): WorkRecord[] {
    const updatedWorkRecords: WorkRecord[] = [];

    for (let i = 0; i < workRecords.length; i++) {
      let overlap = false;

      for (let j = 0; j < updatedWorkRecords.length; j++) {
        if (workRecords[i].startTime <= updatedWorkRecords[j].endTime! && workRecords[i].endTime! >= updatedWorkRecords[j].startTime) {
          overlap = true;
          updatedWorkRecords[j] = this.mergeWorkRecords(updatedWorkRecords[j], workRecords[i]);
        }
      }
      if (overlap == false) {
        updatedWorkRecords.push(workRecords[i]);
      }
    }
    return updatedWorkRecords;
  }

  private mergeWorkRecords(existingWorkRecord: WorkRecord, newWorkRecord: WorkRecord) {
    const wr: IWorkRecord = {
      id: existingWorkRecord.id,
      uuid: existingWorkRecord.uuid,
      editorId: existingWorkRecord.editorId,
      startTime: new Date(Math.min(existingWorkRecord.startTime.getTime(), newWorkRecord.startTime.getTime())),
      endTime: new Date(Math.max(existingWorkRecord.endTime!.getTime(), newWorkRecord.endTime!.getTime())),
      comment: existingWorkRecord.comment,
      commentEditor: existingWorkRecord.commentEditor,
      notified: existingWorkRecord.notified,
      status: existingWorkRecord.status,
    };

    const workRecord: WorkRecord = new WorkRecord(wr);

    return workRecord;
  }

  private calculatePauseBetweenWorkRecords(workRecords: WorkRecord[]): number {
    let wr = [...workRecords];
    wr = wr.filter((record) => record.endTime !== undefined);
    let p = 0;

    if (wr.length == 1) {
      return p;
    }

    for (let i = 1; i < wr.length; i++) {
      const previousEndTime = wr[i - 1].endTime!.getTime();
      const nextStartTime = wr[i].startTime.getTime();

      const timeDifferenceInMillis = Math.abs(nextStartTime - previousEndTime);
      const timeDifferenceInMinutes = timeDifferenceInMillis / (1000 * 60);

      p = p + timeDifferenceInMinutes;
    }
    return p;
  }

  private async getDeviceID() {
    try {
      return await Device.getId();
    } catch (error) {
      this.store.dispatch(new UIActions.Error({ error: error, internal: true }));
      return undefined;
    }
  }

  private async getDeviceInfo() {
    try {
      return await Device.getInfo();
    } catch (error) {
      this.store.dispatch(new UIActions.Error({ error: error, internal: true }));
      return undefined;
    }
  }

  private async getBatteryInfo() {
    try {
      return await Device.getBatteryInfo();
    } catch (error) {
      this.store.dispatch(new UIActions.Error({ error: error, internal: true }));
      return undefined;
    }
  }

  private async getDeviceLanguageCode() {
    try {
      return await Device.getLanguageCode();
    } catch (error) {
      this.store.dispatch(new UIActions.Error({ error: error, internal: true }));
      return undefined;
    }
  }
}

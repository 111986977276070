import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { map, switchMap, tap, catchError } from "rxjs/operators";
import { of } from "rxjs";
import { IAppState } from "src/app/core/store/app/app.reducer";
import { StorageService } from "src/app/shared/services/storage.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { WorkRecord } from "src/app/core/models/work-record.model";
import { NavController } from "@ionic/angular";
import { utcToZonedTime } from "date-fns-tz";
import { format } from "date-fns";
import { Location } from "@angular/common";
import { SharedService } from "src/app/shared/services/shared.service";
import * as WorkRecordsActions from "./workRecords.actions";
import * as UIActions from 'src/app/core/store/ui/ui.actions';
import { NGXLogger } from "ngx-logger";

@Injectable()
export class WorkRecordsEffects {

    fetch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkRecordsActions.FETCH),
            switchMap((action: WorkRecordsActions.Fetch) => {
                this.logger.info(`Fetching workrecods of user ${action.payload.id}`);
                return this.http.get<any>(environment.apiUrl + 'employees/' + action.payload.id + '/workrecords')
                    .pipe(
                        map((response: any) => {
                            const wR: WorkRecord[] = [];
                            response.data.forEach((workRecord: any, index: number) => {
                                var w: WorkRecord = this.sharedService.createWorkRecordFromResponse(workRecord);
                                wR.push(w);
                            });
                            if (action.payload.navigate) {
                                this.location.back();
                            }
                            return new WorkRecordsActions.SetWorkRecords(wR);
                        }),
                        catchError((errorRes) => {
                            this.navController.navigateBack(['app', 'startseite'], { replaceUrl: true });
                            return of(new UIActions.Error({ error: errorRes, internal: false }));
                        })
                    )
            })
        )
    );

    add$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkRecordsActions.ADD_WORKRECORD),
            tap((action: WorkRecordsActions.AddWorkRecord) => {
                this.store.dispatch(new UIActions.StartLoading());
                this.logger.info(`Adding new work record for user ${action.payload.workRecord.uuid}`);
            }),
            switchMap((action: WorkRecordsActions.AddWorkRecord) => {
                const workRecord: WorkRecord = action.payload.workRecord;
                return this.http.post<any>(environment.apiUrl + 'workrecords', {
                    employee_id: workRecord.uuid,
                    editor_id: workRecord.editorId,
                    start_time: format(utcToZonedTime(workRecord.startTime, 'UTC'), 'yyyy-MM-dd HH:mm:ss'),
                    end_time: workRecord.endTime ? format(utcToZonedTime(workRecord.endTime, 'UTC'), 'yyyy-MM-dd HH:mm:ss') : null,
                    comment: workRecord.comment,
                    comment_editor: workRecord.commentEditor,
                    notified: workRecord.notified,
                    status: workRecord.status,
                }, { observe: 'response' }).pipe(
                    map(() => {
                        return new WorkRecordsActions.Fetch({ id: workRecord.uuid, navigate: action.payload.navigate });
                    }),
                    catchError((errorRes) => {
                        this.navController.navigateBack(['app', 'zeitkonto', 'arbeitszeiten'], { replaceUrl: true });
                        return of(new UIActions.Error({ error: errorRes, internal: false }));
                    })
                );
            })
        )
    )

    update$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkRecordsActions.UPDATE_WORKRECORD),
            tap((action: WorkRecordsActions.UpdateWorkRecord) => {
                this.store.dispatch(new UIActions.StartLoading());
                this.logger.info(`Updating work record with id ${action.payload.workRecord.id} of user ${action.payload.workRecord.uuid}`);
            }),
            switchMap((action: WorkRecordsActions.UpdateWorkRecord) => {
                const workRecord: WorkRecord = action.payload.workRecord
                return this.http.patch<any>(environment.apiUrl + 'workrecords/' + workRecord.id, {
                    employee_id: workRecord.uuid,
                    editor_id: workRecord.editorId,
                    start_time: format(utcToZonedTime(workRecord.startTime, 'UTC'), 'yyyy-MM-dd HH:mm:ss'),
                    end_time: workRecord.endTime ? format(utcToZonedTime(workRecord.endTime, 'UTC'), 'yyyy-MM-dd HH:mm:ss') : null,
                    comment: workRecord.comment,
                    comment_editor: workRecord.commentEditor,
                    notified: workRecord.notified,
                    status: workRecord.status,
                }, { observe: 'response' }).pipe(
                    map(() => {
                        return new WorkRecordsActions.Fetch({ id: workRecord.uuid, navigate: action.payload.navigate });
                    }),
                    catchError((errorRes) => {
                        this.navController.navigateBack(['app', 'zeitkonto', 'arbeitszeiten'], { replaceUrl: true });
                        return of(new UIActions.Error({ error: errorRes, internal: false }));
                    })
                );
            })
        )
    )

    delete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkRecordsActions.DELETE_WORKRECORD),
            tap((action: WorkRecordsActions.DeleteWorkRecord) => {
                this.store.dispatch(new UIActions.StartLoading());
                this.logger.info(`Deleting work record with id ${action.payload.workRecord.id} of user ${action.payload.workRecord.uuid}`);
            }),
            switchMap((action: WorkRecordsActions.DeleteWorkRecord) => {
                const workRecord: WorkRecord = action.payload.workRecord
                return this.http.delete<any>(environment.apiUrl + 'workrecords/' + workRecord.id, { observe: 'response' }).pipe(
                    map(() => {
                        return new WorkRecordsActions.Fetch({ id: workRecord.uuid, navigate: action.payload.navigate });
                    }),
                    catchError((errorRes) => {
                        this.navController.navigateBack(['app', 'zeitkonto', 'arbeitszeiten'], { replaceUrl: true });
                        return of(new UIActions.Error({ error: errorRes, internal: false }));
                    })
                );
            })
        )
    )

    set$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkRecordsActions.SET_WORKRECORD),
            map(() => {
                return new UIActions.StopLoading();
            }),
            catchError((errorRes) => {
                return of(new UIActions.Error({ error: errorRes, internal: false }));
            })
        )
    )

    checkWorkday$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkRecordsActions.CHECK_WORKDAY),
            map((action: WorkRecordsActions.CheckWorkday) => {
                const isWorkday = this.sharedService.isWorkingDay();
                return new WorkRecordsActions.SetWorkday(isWorkday);
            }),
            catchError((errorRes) => {
                return of(new UIActions.Error({ error: errorRes, internal: false }));
            })
        )
    )

    constructor(
        private actions$: Actions,
        private store: Store<IAppState>,
        private http: HttpClient,
        private navController: NavController,
        private location: Location,
        private sharedService: SharedService,
        private logger: NGXLogger
    ) { }
}
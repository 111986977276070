import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, catchError, switchMap, tap, throwError } from 'rxjs';
import { AuthData } from 'src/app/core/models/auth-data.model';
import { IAppState } from 'src/app/core/store/app/app.reducer';
import * as AuthActions from '../store/auth/auth.actions';
import * as UIActions from '../store/ui/ui.actions';
import { HttpService } from 'src/app/shared/services/http.service';
import { addSeconds } from 'date-fns';
import { SharedService } from 'src/app/shared/services/shared.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  private authData?: AuthData;

  constructor(private store: Store<IAppState>, private http: HttpService, private sharedService: SharedService) {
    this.store.select('account').subscribe(state => {
      if (state.authData) {
        this.authData = state.authData;
      }
    })
   }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.url.includes('ouath')) {
      return next.handle(req);
    }

    if (this.authData) {
      req = req.clone({
        setHeaders: { Authorization: `${this.authData.tokenType} ${this.authData.accessToken}` }
      })
    }

    return next.handle(req).pipe(
      catchError(error => {

        if (error.error.code === 401 && this.authData && !this.authData.isTokenValid(this.sharedService.serverTime)) {

          return this.http.refreshToken(this.authData.refreshToken).pipe(
            tap(
              () => {
                this.store.dispatch(new UIActions.StartLoading());
              }
            ),
            switchMap((response: HttpResponse<any>) => {
              const headers = response.headers;
              const serverTime = headers.get('Server-Time');
              let currentDate = this.sharedService.convertUTCDateTimeToLocal(serverTime!);
              currentDate = addSeconds(currentDate, response.body.expires_in);
              const authData = new AuthData({
                tokenType: response.body.token_type,
                expiresIn: response.body.expires_in,
                accessToken: response.body.access_token,
                refreshToken: response.body.refresh_token,
                tokenExpirationDate: currentDate,
              });
              this.store.dispatch(new AuthActions.RefreshAuthData(authData));
              req = req.clone({
                setHeaders: { Authorization: `${authData.tokenType} ${authData.accessToken}` }
              })
              return next.handle(req);
            }),
            catchError(error => {
              return throwError(error);
            })
          )
        }

        return throwError(error);
      })
    );
  }
}

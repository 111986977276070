import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { catchError, map, switchMap, withLatestFrom } from "rxjs/operators";
import { from, of, tap } from "rxjs";
import { StorageService } from "src/app/shared/services/storage.service";
import { Employee } from "../../models/employee.model";
import { Store } from "@ngrx/store";
import { IAppState } from "../app/app.reducer";
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthData } from '../../models/auth-data.model';
import { addSeconds } from 'date-fns';
import { EMessageType } from '../../enums/message-type.enum';
import { EStorageKey } from '../../enums/storage-key.enum';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { SharedService } from 'src/app/shared/services/shared.service';
import * as UIActions from '../ui/ui.actions';
import * as AuthActions from './auth.actions';
import * as WorkRecordActions from '../workRecords/workRecords.actions';
import * as AbsencesActions from 'src/app/core/store/absences/absences.actions';
import * as NotificationActions from 'src/app/core/store/notifications/notifications.actions';
import { Admin } from '../../models/admin.model';
import { NGXLogger } from "ngx-logger";
import { TranslateService } from "@ngx-translate/core";
import { AuthState } from "./auth.reducer";
import { Board } from "../../models/board.model";

@Injectable()
export class AuthEffects {
  startLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.START_LOGIN),
      tap((action: AuthActions.StartLogin) => {
        this.logger.info('Fetching auth token')
        this.store.dispatch(new UIActions.StartLoading());
      }),
      switchMap((action: AuthActions.StartLogin) => {
        return this.http
          .post<any>(
            environment.apiUrl + "oauth/token",
            {
              grant_type: "password",
              client_id: environment.client_id,
              client_secret: environment.client_secret,
              username: action.payload.email,
              password: action.payload.password,
            },
            { observe: "response" }
          )
          .pipe(
            map((response: HttpResponse<any>) => {
              const headers = response.headers;
              const serverTime = headers.get("Server-Time");
              let currentDate = this.sharedService.convertUTCDateTimeToLocal(
                serverTime!
              );
              currentDate = addSeconds(currentDate, response.body.expires_in);
              const authData = new AuthData({
                tokenType: response.body.token_type,
                expiresIn: response.body.expires_in,
                accessToken: response.body.access_token,
                refreshToken: response.body.refresh_token,
                tokenExpirationDate: currentDate,
              });
              this.storage.set(
                EStorageKey.AUTHDATA,
                this.sharedService.createAuthDataForStore(authData)
              );
              return new AuthActions.FetchAccount(authData);
            }),
            catchError((errorRes) => {
              this.logger.error(errorRes.message);
              return of(new AuthActions.LoginFailed(errorRes));
            })
          );
      })
    )
  );

  fetchAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.FETCH_ACCOUNT),
      switchMap((action: AuthActions.FetchAccount) => {
        this.logger.info(`Fetching personal account data from server`);
        return this.http.get(environment.apiUrl + "users/me").pipe(
          map((response: any) => {
            const account = this.sharedService.createUserFromAPI(response.data);
            return new AuthActions.Login(account);
          }),
          catchError((errorRes) => {
            return of(new AuthActions.LoginFailed(errorRes));
          })
        );
      })
    )
  );

  refreshAuthData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.REFRESH_AUTHDATA),
      tap((action: AuthActions.RefreshAuthData) => {
        this.storage.set(
          EStorageKey.AUTHDATA,
          this.sharedService.createAuthDataForStore(action.payload)
        ).then(() => {
          this.logger.info(`Refreshed auth data in storage`);
        });
      }),
      switchMap((action: AuthActions.RefreshAuthData) => {
        return of(new UIActions.StopLoading());
      }),
      catchError((error) => {
        return of(new UIActions.Error({ error: error, internal: false }));
      })
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.UPDATE),
      tap((action: AuthActions.Update) => {
        this.store.dispatch(new UIActions.StartLoading());
        this.logger.info(`Updating personal data of user ${action.payload.userId}`);
      }),
      switchMap((action: AuthActions.Update) => {
        const user = action.payload;
        return this.http
          .patch<any>(
            environment.apiUrl + "users/me/edit",
            {
              email: user.email,
              firstName: user.firstName,
              lastName: user.lastName,
              phone: user.phone,
              address: user.address,
              houseNr: user.houseNr,
              zipCode: user.zipCode,
              city: user.city,
            },
            { observe: "response" }
          )
          .pipe(
            map(() => {
              this.navController.navigateBack(["app", "mitglieder", "me"], {
                replaceUrl: true,
              });
              return new UIActions.Message({
                message: this.translateService.instant("CORE.MESSAGES.USER_DATA_UPDATED"),
                type: EMessageType.INFORMATION
              });
            }),
            catchError((errorRes) => {
              this.navController.navigateBack(["app", "mitglieder", "me"], {
                replaceUrl: true,
              });
              return of(new UIActions.Error({ error: errorRes, internal: false }));
            })
          );
      })
    )
  );

  passwordUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.PASSWORD_UPDATE),
      tap((action: AuthActions.PasswordUpdate) => {
        this.store.dispatch(new UIActions.StartLoading());
        this.logger.info(`Updating password of user`);
      }),
      switchMap((action: AuthActions.PasswordUpdate) => {
        return this.http
          .post<any>(
            environment.apiUrl + "users/password/new",
            {
              email: action.payload.email,
              password: action.payload.password,
              password_confirmation: action.payload.password_confirmation,
            },
            { observe: "response" }
          )
          .pipe(
            map((response: HttpResponse<any>) => {
              this.store.dispatch(
                new UIActions.Message({
                  message: this.translateService.instant("CORE.MESSAGES.PASSWORD_UPDATED"),
                  type: EMessageType.INFORMATION
                })
              );
              this.router.navigate(["app/startseite"]);
              return new AuthActions.StartLogin({
                email: action.payload.email,
                password: action.payload.password,
              });
            }),
            catchError((errorRes) => {
              return of(new UIActions.Error({ error: errorRes, internal: false }));
            })
          );
      })
    )
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.LOGIN),
      map((action: AuthActions.Login) => {
        if (this.router.url == "/auth") {
          if (action.payload.passwordReset == 0) {
            this.logger.info(`Navigating to reset password page`);
            this.router.navigate(["/app/mitglieder/me/passwort"]);
            this.store.dispatch(
              new UIActions.Message({
                message:
                  this.translateService.instant("CORE.MESSAGES.UPDATE_PASSWORD_NOTE"),
                type: EMessageType.INFORMATION
              })
            );
          } else {
            this.logger.info(`Navigating home after login`);
            this.router.navigate(["/app/startseite"]);
          }
        } else {
          if (
            action.payload instanceof Admin ||
            action.payload instanceof Board
          ) {
            this.store.dispatch(new AbsencesActions.FetchOpen(false));
            this.store.dispatch(new NotificationActions.Fetch(action.payload.userId))
          }

          if (action.payload instanceof Employee) {
            this.store.dispatch(new AbsencesActions.Fetch({ id: action.payload.userId, navigate: false }));
            this.store.dispatch(new WorkRecordActions.Fetch({ id: action.payload.userId, navigate: false }));
            this.store.dispatch(new NotificationActions.Fetch(action.payload.userId))
          }
        }
        return new UIActions.StopLoading();
      }),
      catchError((error) => {
        return of(new UIActions.Error({ error: error, internal: false }));
      })
    )
  )

  fetchAlias$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.FETCH_ALIAS),
      tap((action: AuthActions.FetchAlias) => {
        this.store.dispatch(new UIActions.StartLoading());
        this.logger.info(`Fetching alias data of user ${action.payload}`);
      }),
      switchMap((action: AuthActions.FetchAlias) => {
        return this.http
          .get(environment.apiUrl + "employees/" + action.payload)
          .pipe(
            map((response: any) => {
              const account = this.sharedService.createUserFromAPI(
                response.data
              );
              return new AuthActions.SetAlias(account);
            }),
            catchError((errorRes) => {
              return of(new AuthActions.LoginFailed(errorRes));
            })
          );
      })
    )
  );

  setAlias$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.SET_ALIAS),
      map((action: AuthActions.SetAlias) => {
        this.logger.info(`Setting user alias in store`);
        this.store.dispatch(
          new WorkRecordActions.Fetch({
            id: action.payload.userId,
            navigate: false,
          })
        );
        this.store.dispatch(
          new AbsencesActions.Fetch({
            id: action.payload.userId,
            navigate: false,
          })
        );
        this.store.dispatch(
          new NotificationActions.Fetch(action.payload.userId)
        );
        this.router.navigate(["/app/startseite"]);
        return new UIActions.StopLoading();
      }),
      catchError((error) => {
        return of(new UIActions.Error({ error: error, internal: false }));
      })
    )
  );

  deleteAlias$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.DELETE_ALIAS),
      tap((action: AuthActions.DeleteAlias) => {
        this.logger.info(`Removing user alias from store`);
        this.store.dispatch(new UIActions.StartLoading());
        this.store.dispatch(new AbsencesActions.FetchOpen(false));
      }),
      withLatestFrom(
        this.store.select('account')
      ),
      switchMap(([authAction, accountState]: [AuthActions.DeleteAlias, AuthState]) => {
        this.store.dispatch(new NotificationActions.Fetch(accountState.account!.userId))
        if (authAction.navigate) {
          this.router.navigate(["/app/startseite"]);
        }
        return of(new UIActions.StopLoading());
      }),
      catchError((error) => {
        return of(new UIActions.Error({ error: error, internal: false }));
      })
    )
  );

  autoLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.AUTO_LOGIN),
        switchMap(() => {
          this.logger.info(`Starting auto login`);
          return from(
            this.storage.get(EStorageKey.AUTHDATA).then((val) => {
              return val;
            })
          );
        }),
        tap((a) => {
          if (a) {
            const authData: AuthData =
              this.sharedService.createAuthDataFromInterface(a);
            this.store.dispatch(new AuthActions.FetchAccount(authData));
          }
        }),
        catchError((error) => {
          return of(new UIActions.Error({ error: error, internal: false }));
        })
      ),
    { dispatch: false }
  );

  loginFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.LOGIN_FAILED),
      tap((action: AuthActions.LoginFailed) => {
        this.storage.remove(EStorageKey.AUTHDATA);
        this.router.navigate(["/auth"]);
        this.logger.info(`Login failed`);
      }),
      switchMap((action: AuthActions.LoginFailed) => {
        return of(new UIActions.Error(action.payload));
      }),
      catchError((error) => {
        return of(new UIActions.Error({ error: error, internal: false }));
      })
    )
  );

  passwordReset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.PASSWORD_RESET),
      tap((action: AuthActions.PasswordReset) => {
        this.store.dispatch(new UIActions.StartLoading());
        this.logger.info(`Sending password reset request to server`);
      }),
      switchMap((action: AuthActions.PasswordReset) => {
        return this.http
          .post<any>(
            environment.apiUrl + "users/password/reset",
            {
              email: action.payload,
            },
            { observe: "response" }
          )
          .pipe(
            map((response: HttpResponse<any>) => {
              return new UIActions.Message({
                message: this.translateService.instant("CORE.MESSAGES.NEW_PASSWORD_SEND"),
                type: EMessageType.INFORMATION
              });
            }),
            catchError((errorRes) => {
              return of(new UIActions.Error({ error: errorRes, internal: false }));
            })
          );
      })
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.LOGOUT),
      tap((action: AuthActions.Logout) => {
        this.store.dispatch(new UIActions.StartLoading());
        this.logger.info(`Logging out user from app`);
      }),
      switchMap((action: AuthActions.Logout) => {
        return this.http
          .post<any>(environment.apiUrl + "users/logout", {
            observe: "response",
          })
          .pipe(
            map((response: HttpResponse<any>) => {
              this.storage.remove(EStorageKey.AUTHDATA);
              this.router.navigate(["/auth"]);
              return new UIActions.StopLoading();
            }),
            catchError((errorRes) => {
              return of(new UIActions.Error({ error: errorRes, internal: false }));
            })
          );
      })
    )
  );

  sessionLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.SESSION_LOGOUT),
      tap((action: AuthActions.LoginFailed) => {
        this.logger.info(`Logging out user after session expired`);
        this.storage.remove(EStorageKey.AUTHDATA);
        this.router.navigate(["/auth"]);
      }),
      switchMap((action: AuthActions.LoginFailed) => {
        return of(new UIActions.StopLoading());
      }),
      catchError((error) => {
        return of(new UIActions.Error({ error: error, internal: false }));
      })
    )
  );

  constructor(
    private actions$: Actions,
    private storage: StorageService,
    private store: Store<IAppState>,
    private http: HttpClient,
    private router: Router,
    private navController: NavController,
    private sharedService: SharedService,
    private logger: NGXLogger,
    private translateService: TranslateService
  ) { }
}

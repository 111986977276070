import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/core/store/app/app.reducer';
import * as UIActions from 'src/app/core/store/ui/ui.actions';
import { environment } from 'src/environments/environment';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {

    private previousError: any;
    private get store() {
        return this.injector.get(Store<IAppState>);
    }

    constructor(
        private injector: Injector
    ) {
        super();
    }
    override handleError(error: any): void {
        if (error?.message != this.previousError?.message) {
            super.handleError(error);
            this.store.dispatch(new UIActions.Error({ error: error, internal: false }));
        }
        this.previousError = error;
    }
}